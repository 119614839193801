import { CHANGE_SINGLE_USER, CHANGE_USER_PAGE, CHANGE_NUMBER_OF_ELEMENTS, CHANGE_PAGE, CHANGE_USER_ROLES,  APPEND_USER_ROLE_ATTACHMENTS, REMOVE_USER_ROLE_ATTACHMENTS, CLEAR_ATTACHMENTS, CLEAR_ROLE_VERSIONS } from "../actionTypes";

const initialState = {
    users: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },
    user: {
        dbId: 0,
        email: "",
        password: "",
        personName: "",
        changePasswordMail: false
    },
    numberOfElements: 10,
    page: 1,
    userRoles: [],
    attachments: []
};


const benutzerverwaltung = (state = initialState, action) => {
    switch(action.type) {
        case CHANGE_SINGLE_USER: {
            return {...state, user: action.payload}
        }
        case CHANGE_USER_PAGE: {
            return {...state, users: action.payload}
        }
        case CHANGE_NUMBER_OF_ELEMENTS: {
            return {...state, numberOfElements: action.payload}
        }
        case CHANGE_PAGE: {
            return {...state, page: action.payload}
        }
        case CHANGE_USER_ROLES: {
            return {...state, userRoles: action.payload}
        }
        case APPEND_USER_ROLE_ATTACHMENTS: {
            var element = state.attachments.filter(a => a.roleId === action.payload.roleId);

            var attachmentsCopy = [...state.attachments.slice()];
            console.log(attachmentsCopy);
            if(element.length > 0)
            {
                var elementOrig = attachmentsCopy.filter(a => a.roleId === action.payload.roleId)[0]
                var elementOrigCopy = {...elementOrig};

                elementOrigCopy.isAttached = action.payload.isAttached;
                var indexElement = attachmentsCopy.indexOf(elementOrig);
                attachmentsCopy[indexElement] = elementOrigCopy;
                
            }
            else
            {
                attachmentsCopy.push(action.payload);
                console.log("Push new");
            }


            return {...state, attachments: attachmentsCopy}
        }
        case CLEAR_ATTACHMENTS: {
            return {...state, attachments: [...initialState.attachments]}
        }
        default:
            return state;
    }
}

export default benutzerverwaltung;