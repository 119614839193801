import { CHANGE_SINGLE_CUSTOMER, CHANGE_CUSTOMER_PAGE, CHANGE_NUMBER_OF_ELEMENTS, CHANGE_PAGE, CHANGE_CUSTOMER_DOCUMENTS, CHANGE_CUSTOMER_DOCUMENT_ATTACHMENTS, APPEND_CUSTOMER_DOCUMENT_ATTACHMENTS, REMOVE_CUSTOMER_DOCUMENT_ATTACHMENTS, CLEAR_ATTACHMENTS, CLEAR_DOCUMENT_VERSIONS, CHANGE_NOTES_PAGE, CHANGE_NOTE } from "../actionTypes";

const initialState = {
    customers: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },
    notes: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },

    note: {
        noteVal: "",
    },
    customer: {
        dbId: 0,
        name: "",
        customerId: "",
        website: "",
        ieblIsPrivacyConsultant: false
    },
    numberOfElements: 10,
    page: 1,
    customerDocuments: [],
    attachments: []
};


const kundenverwaltung = (state = initialState, action) => {
    switch(action.type) {
        case CHANGE_SINGLE_CUSTOMER: {

            var customer = action.payload;
            customer.ieblIsPrivacyConsultant = customer.privacy_consultant_id > 0;

            return {...state, customer: customer}
        }
        case CHANGE_CUSTOMER_PAGE: {
            return {...state, customers: action.payload}
        }
        case CHANGE_NUMBER_OF_ELEMENTS: {
            return {...state, numberOfElements: action.payload}
        }
        case CHANGE_PAGE: {
            return {...state, page: action.payload}
        }
        case CHANGE_CUSTOMER_DOCUMENTS: {
            return {...state, customerDocuments: action.payload}
        }
        case APPEND_CUSTOMER_DOCUMENT_ATTACHMENTS: {
            var element = state.attachments.filter(a => a.documentVersionId === action.payload.documentVersionId);

            var attachmentsCopy = [...state.attachments.slice()];
            console.log(attachmentsCopy);
            if(element.length > 0)
            {
                var elementOrig = attachmentsCopy.filter(a => a.documentVersionId === action.payload.documentVersionId)[0]
                var elementOrigCopy = {...elementOrig};

                elementOrigCopy.isAttached = action.payload.isAttached;
                var indexElement = attachmentsCopy.indexOf(elementOrig);
                attachmentsCopy[indexElement] = elementOrigCopy;
                
            }
            else
            {
                attachmentsCopy.push(action.payload);
                console.log("Push new");
            }


            return {...state, attachments: attachmentsCopy}
        }

        case CHANGE_NOTES_PAGE: {
            return {...state, notes: action.payload}
        }

        case CHANGE_NOTE: {
            return {...state, note: action.payload}
        }

        case CLEAR_ATTACHMENTS: {
            return {...state, attachments: [...initialState.attachments]}
        }
        default:
            return state;
    }
}

export default kundenverwaltung;