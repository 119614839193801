import { CHANGE_RIGHT_PAGE, CHANGE_SINGLE_ROLE, CHANGE_ROLE_PAGE, CHANGE_NUMBER_OF_ELEMENTS, CHANGE_PAGE, CHANGE_ROLE_DOCUMENTS, CHANGE_ROLE_DOCUMENT_ATTACHMENTS, APPEND_ROLE_RIGHT_ATTACHMENTS, REMOVE_ROLE_DOCUMENT_ATTACHMENTS, CLEAR_ATTACHMENTS, CLEAR_DOCUMENT_VERSIONS, CHANGE_RIGHT_PAGE_PUSH, CHANGE_ROLE_PAGE_PUSH, CLEAR_ROLES, CLEAR_RIGHTS } from "../actionTypes";

const initialState = {
    roles: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },
    rights: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },
    role: {
        dbId: 0,
        role_name: "",
    },
    numberOfElements: 10,
    page: 1,
    roleRights: [],
    attachments: []
};


const rollenverwaltung = (state = initialState, action) => {
    switch(action.type) {
        case CHANGE_SINGLE_ROLE: {
            return {...state, role: action.payload}
        }
        case CHANGE_ROLE_PAGE: {
            return {...state, roles: action.payload}
        }
        case CHANGE_RIGHT_PAGE: {
            return {...state, rights: action.payload}
        }
        case CHANGE_RIGHT_PAGE_PUSH: {
            var rights = {...state.rights};
            var valuesCopy = [...state.rights.values]
            
            action.payload.values.map((dV) => {
                valuesCopy.push(dV);
            });
            rights.page = action.payload.page;
            rights.pageCount = action.payload.pageCount;
            rights.elementCount = action.payload.elementCount;
            rights.values =[...valuesCopy];

            return {...state, rights: rights}
        }
        case CHANGE_ROLE_PAGE_PUSH: {
            var roles = {...state.roles};
            var valuesCopy = [...state.roles.values]
            
            action.payload.values.map((dV) => {
                valuesCopy.push(dV);
            });
            roles.page = action.payload.page;
            roles.pageCount = action.payload.pageCount;
            roles.elementCount = action.payload.elementCount;
            roles.values =[...valuesCopy];

            return {...state, roles: roles}
        }
        case CHANGE_NUMBER_OF_ELEMENTS: {
            return {...state, numberOfElements: action.payload}
        }
        case CHANGE_PAGE: {
            return {...state, page: action.payload}
        }
        case CHANGE_ROLE_DOCUMENTS: {
            return {...state, roleRights: action.payload}
        }
        case APPEND_ROLE_RIGHT_ATTACHMENTS: {
            var element = state.attachments.filter(a => a.rightId === action.payload.rightId);

            var attachmentsCopy = [...state.attachments.slice()];
            console.log(attachmentsCopy);
            if(element.length > 0)
            {
                var elementOrig = attachmentsCopy.filter(a => a.rightId === action.payload.rightId)[0]
                var elementOrigCopy = {...elementOrig};

                elementOrigCopy.isAttached = action.payload.isAttached;
                var indexElement = attachmentsCopy.indexOf(elementOrig);
                attachmentsCopy[indexElement] = elementOrigCopy;
                
            }
            else
            {
                attachmentsCopy.push(action.payload);
                console.log("Push new");
            }


            return {...state, attachments: attachmentsCopy}
        }
        case CLEAR_ATTACHMENTS: {
            return {...state, attachments: [...initialState.attachments]};
        }
        case CLEAR_ROLES: {
            return {...state, roles: {...initialState.roles}};
        }
        case CLEAR_RIGHTS: {
            return {...state, rights: {...initialState.rights}};
        }
        default:
            return state;
    }
}

export default rollenverwaltung;