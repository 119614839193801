import { useToast } from '@chakra-ui/react';
import { SHOW_MODAL_DIALOG, HIDE_MODAL_DIALOG } from './actionTypes';

export const showModal = (modalTypeName, modalPropsConfig) => {
    return {
        type: SHOW_MODAL_DIALOG,
        payload: {
            modalType: modalTypeName,
            modalProps: modalPropsConfig
        }
    };
};

export const hideModal = () => {
    return {
        type: HIDE_MODAL_DIALOG
    };
};