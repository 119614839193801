import { CHANGE_SINGLE_GRUPPENHAUS, CHANGE_GRUPPENHAUS_PAGE , CHANGE_NUMBER_OF_ELEMENTS, CHANGE_PAGE } from "../actionTypes";

const initialState = {
    gruppenhauses: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },
    gruppenhaus: {
        id: 0,
        name: "",
        url: ""
    },
    numberOfElements: 10,
    page: 1,
    roleRights: [],
    attachments: []
};

function gruppenhausverwaltung(state = initialState, action) {
    switch(action.type) {
        case CHANGE_SINGLE_GRUPPENHAUS: {
            return {...state, gruppenhaus: action.payload}
        }
        case CHANGE_GRUPPENHAUS_PAGE: {
            return {...state, gruppenhauses: action.payload}
        }
        case CHANGE_NUMBER_OF_ELEMENTS: {
            return {...state, numberOfElements: action.payload}
        }
        case CHANGE_PAGE: {
            return {...state, page: action.payload}
        }
        
        default:
            return state;
    }
}

export default gruppenhausverwaltung;