import { CHANGE_DOCUMENTS_PAGE, CHANGE_DOCUMENT_VERSION, CHANGE_DOCUMENT_VERSION_PAGE, CHANGE_DOCUMENT_VERSION_PAGE_PUSH, CHANGE_NUMBER_OF_ELEMENTS, CHANGE_PAGE, CHANGE_SINGLE_DOCUMENT, CLEAR_DOCUMENT_VERSIONS, WRITE_DOCUMENT_VERSION_BUFFER, WRITE_DOCUMENT_VERSION_CHANGES } from "../actionTypes";

const initialState = {
    documents: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },
    document: {
        dbId: 0,
        documentName: "",
    },
    documentVersions: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },
    documentVersionsBuffer: [],
    documentVersion: {
        dbId: 0,
        version: "",
        originalFileName: "",
        documentPath: "",
        documentHash: "",
        changelog: "",
        isGlobalDocument: ""
    },
    numberOfElements: 10,
    page: 1,
    changes:[]
};


const dokumentenverwaltung = (state = initialState, action) => {
    switch(action.type) {
        case CHANGE_SINGLE_DOCUMENT: {
            return {...state, document: action.payload}
        }
        case CHANGE_DOCUMENT_VERSION: {
            return {...state, documentVersion: action.payload}
        }
        case CHANGE_DOCUMENTS_PAGE: {
            return {...state, documents: action.payload}
        }
        case CHANGE_DOCUMENT_VERSION_PAGE: {
            return {...state, documentVersions: action.payload}
        }
        case CHANGE_DOCUMENT_VERSION_PAGE_PUSH: {
            var documentVersions = {...state.documentVersions};
            var valuesCopy = [...state.documentVersions.values]
            
            action.payload.values.map((dV) => {
                valuesCopy.push(dV);
            });
            documentVersions.page = action.payload.page;
            documentVersions.pageCount = action.payload.pageCount;
            documentVersions.elementCount = action.payload.elementCount;
            documentVersions.values =[...valuesCopy];

            return {...state, documentVersions: documentVersions}
        }
        case CHANGE_NUMBER_OF_ELEMENTS: {
            return {...state, numberOfElements: action.payload}
        }
        case CHANGE_PAGE: {
            return {...state, page: action.payload}
        }
        case WRITE_DOCUMENT_VERSION_BUFFER: {
            return {...state, documentVersionsBuffer: action.payload}
        }
        case WRITE_DOCUMENT_VERSION_CHANGES: {
            return {...state, changes: action.payload}
        }
        case CLEAR_DOCUMENT_VERSIONS: {
            return {...state, documentVersions: {...initialState.documentVersions}}
        }
        default:
            return state;
    }
}

export default dokumentenverwaltung;