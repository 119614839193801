import { SHOW_LOADING_OVERLAY, HIDE_LOADING_OVERLAY, CHANGE_DOCUMENT, CHANGE_DOCUMENT_VERSION } from './actionTypes';

export const showLoading = message => {
    return {
        type: SHOW_LOADING_OVERLAY,
        payload: message
    };
};

export const hideLoading = () => {
    return {
        type: HIDE_LOADING_OVERLAY
    };
};