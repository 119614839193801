import { configureStore, combineReducers } from '@reduxjs/toolkit'

import auth from './components/pages/Login/redux/reducer/auth';
import message from './components/pages/Login/redux/reducer/message';
import loadingOverlay from './components/common/Loading/redux/reducer/loadingOverlay';
//verwaltung
import kundenverwaltung from './components/pages/Kundenverwaltung/redux/reducer/index';
import dokumentenverwaltung from './components/pages/Dokumentenverwaltung/redux/reducer';

import userverwaltung from './components/pages/Userverwaltung/redux/reducer';
import rollenverwaltung from './components/pages/Rollenverwaltung/redux/reducer';

import teilnehmerverwaltung from './components/pages/Teilnehmerverwaltung/redux/reducer';

import anredeverwaltung from './components/pages/Anredeverwaltung/redux/reducer';
import amtverwaltung from './components/pages/Amtverwaltung/redux/reducer';
import funktionverwaltung from './components/pages/Funktionverwaltung/redux/reducer';
import kursverwaltung from './components/pages/Kursverwaltung/redux/reducer';
import psgesamtliste from './components/pages/PSGesamtliste/redux/reducer';
import exportverwaltung from './components/pages/Exportverwaltung/redux/reducer';
import kirchenkreisverwaltung from './components/pages/Kirchenkreisverwaltung/redux/reducer';
import kirchengemeindeverwaltung from './components/pages/Kirchengemeindeverwaltung/redux/reducer';
import sprengelverwaltung from './components/pages/SprengelVerwaltung/redux/reducer';
import landeskirchenverwaltung from './components/pages/Landeskirchenverwaltung/redux/reducer';
import gruppenhausverwaltung from './components/pages/Gruppenhausverwaltung/redux/reducer';

//modal
import modalDialog from './components/common/Modal/redux/reducer/index';

import settings from './components/pages/Settings/redux/reducer';

const mainReducer = combineReducers({
  auth,
  message,
  loadingOverlay,
  kundenverwaltung,
  dokumentenverwaltung,
  userverwaltung,
  teilnehmerverwaltung,
  anredeverwaltung,
  amtverwaltung,
  funktionverwaltung,
  kursverwaltung,
  rollenverwaltung,
  psgesamtliste,
  modalDialog,
  settings,
  sprengelverwaltung,
  exportverwaltung,
  kirchenkreisverwaltung,
  kirchengemeindeverwaltung,
  landeskirchenverwaltung,
  gruppenhausverwaltung
});

export default configureStore({
  reducer: mainReducer,
})