export const CHANGE_LANDESKIRCHE = 'CHANGE_LANDESKIRCHE';
export const CHANGE_SPRENGEL = 'CHANGE_SPRENGEL';
export const CHANGE_KIRCHENKREIS = 'CHANGE_KIRCHENKREIS';
export const CHANGE_KIRCHENGEMEINDE = 'CHANGE_KIRCHENGEMEINDE';
export const CHANGE_ANREDE = 'CHANGE_ANREDE';
export const CHANGE_TITEL = 'CHANGE_TITEL';
export const CHANGE_FUNKTION = 'CHANGE_FUNKTION';
export const CHANGE_AMT = 'CHANGE_AMT';
export const CHANGE_KURS = 'CHANGE_KURS';
export const CHANGE_KIND = 'CHANGE_KIND';
export const CHANGE_KINDER = 'CHANGE_KINDER';
export const CHANGE_TEILNEHMERS = 'CHANGE_TEILNEHMERS';
export const CHANGE_SINGLE_TEILNEHMER = 'CHANGE_SINGLE_TEILNEHMER';
export const CHANGE_TEILNEHMERS_PAGE = 'CHANGE_TEILNEHMER_PAGE';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_NUMBER_OF_ELEMENTS = 'CHANGE_NUMBER_OF_ELEMENTS';
export const CHANGE_TEILNEHMERPS = 'CHANGE_TEILNEHMERPS';
export const CHANGE_SINGLE_KURS = 'CHANGE_SINGLE_KURS';
//VIKARIATSVERWALTUNG
export const CHANGE_VIKARIATSLEITER = 'CHANGE_VIKARIATSLEITER';
export const CHANGE_SINGLE_VIKARIATSLEITER = 'CHANGE_SINGLE_VIKARIATSLEITER';
export const CHANGE_VIKARIATSLEITER_PAGE = 'CHANGE_VIKARIATSLEITER_PAGE';
export const CHANGE_VIKARIATSLEITER_SEL = 'CHANGE_VIKARIATSLEITER_SEL'; 
export const CHANGE_TLNHATKURS = 'CHANGE_TLNHATKURS';
export const CHANGE_TLNTABLEDATA = 'CHANGE_TLNTABLEDATA';
export const CHANGE_VLTABLEDATA = 'CHANGE_VLTABLEDATA';





