export const CHANGE_SINGLE_ROLE = 'CHANGE_SINGLE_ROLE';
export const CHANGE_ROLE_PAGE = 'CHANGE_ROLE_PAGE';
export const CHANGE_NUMBER_OF_ELEMENTS = 'CHANGE_NUMBER_OF_ELEMENTS';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_ROLE_DOCUMENTS = 'CHANGE_ROLE_DOCUMENTS';
export const APPEND_ROLE_RIGHT_ATTACHMENTS = 'APPEND_ROLE_RIGHT_ATTACHMENTS';
export const REMOVE_ROLE_DOCUMENT_ATTACHMENTS = 'REMOVE_ROLE_DOCUMENT_ATTACHMENTS';
export const CLEAR_ATTACHMENTS = 'CLEAR_ATTACHMENTS';

export const CHANGE_RIGHT_PAGE = 'CHANGE_RIGHT_PAGE';
export const CHANGE_RIGHT_PAGE_PUSH = 'CHANGE_RIGHT_PAGE_PUSH';
export const CHANGE_ROLE_PAGE_PUSH = 'CHANGE_ROLE_PAGE_PUSH';

export const CLEAR_ROLES = 'CLEAR_ROLES';
export const CLEAR_RIGHTS = 'CLEAR_RIGHTS';