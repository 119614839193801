export const getUserPage = state => {
    return state.userverwaltung.users;
}

export const getSingleUser = state => {
    return state.userverwaltung.user;
}

export const getNumberOfElements = (state) => {
    return state.userverwaltung.numberOfElements;
}

export const getPageNumber = (state) => {
    return state.userverwaltung.page;
}

export const getUserRoles = (state) => {
    return state.userverwaltung.userRoles
}

export const getUserRoleAttachments = (state) => {
    return state.userverwaltung.attachments;
}