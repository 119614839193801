export const getRolePage = state => {
    return state.rollenverwaltung.roles;
}

export const getRightPage = state => {
    return state.rollenverwaltung.rights;
}

export const getSingleRole = state => {
    return state.rollenverwaltung.role;
}

export const getRoleRights = state => {
    return state.rollenverwaltung.roleRights;
}



export const getNumberOfElements = (state) => {
    return state.rollenverwaltung.numberOfElements;
}

export const getPageNumber = (state) => {
    return state.rollenverwaltung.page;
}

export const getRoleRightAttachments = (state) => {
    return state.rollenverwaltung.attachments;
}