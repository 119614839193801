import { CHANGE_SINGLE_KIRCHENKREIS, CHANGE_KIRCHENKREISE_PAGE , CHANGE_NUMBER_OF_ELEMENTS, CHANGE_PAGE, CHANGE_SPRENGELS } from "../actionTypes";



const initialState = {
    sprengels: [],
    kirchenkreise: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },
    kirchenkreis: {
        kkid: -1,
        kirchenkreisName: "",
        sprengelID: -1,
    },
    numberOfElements: 10,
    page: 1,
    
};


const Kirchenkreisverwaltung = (state = initialState, action) => {
    switch(action.type) {
        case CHANGE_SINGLE_KIRCHENKREIS: {
            return {...state, kirchenkreis: action.payload}
        }
        case CHANGE_KIRCHENKREISE_PAGE: {
            return {...state, kirchenkreise: action.payload}
        }
        case CHANGE_NUMBER_OF_ELEMENTS: {
            return {...state, numberOfElements: action.payload}
        }
        case CHANGE_PAGE: {
            return {...state, page: action.payload}
        }
        case CHANGE_SPRENGELS: {
            return {...state, sprengels: action.payload}
        }
        
        default:
            return state;
    }
}

export default Kirchenkreisverwaltung;