import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    AlertDialog,
    AlertDialogHeader,
    AlertDialogFooter,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogBody,
    Box,
    Select,
    Button,
    ButtonGroup,
    Progress,
    Input,
    useColorModeValue,
    useDisclosure,
    FormControl,
    FormLabel,
    Checkbox,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    HStack
} from '@chakra-ui/react';
import { useTable, useSortBy } from 'react-table'
import Datatable from '../../common/Datatable';
import { FiPlus, FiEdit2, FiFile } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import { VscTriangleDown, VscTriangleRight } from 'react-icons/vsc';
import { getUserRoleAttachments, getUserRoles, getUserPage, getNumberOfElements, getPageNumber, getSingleUser } from './redux/selectors';
import { changeSingleUser, getSingleUserFromServer, getUserPageFromServer, createUser, updateUser, changeNumberOfElements, changeNumberOfPage, deleteUser, generateRoles, hasRolesOnServer, appendeUserRoleAttachments, bulkAttachRoles, bulkDetachRoles, clearAttachments} from './redux/actions';
import { getRolePageISFromServer, clearRoles } from '../Rollenverwaltung/redux/actions';
import { connect } from 'react-redux';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { token, url } from '../../../services/api';
import api from '../../../services/api';
import { getRolePage } from '../Rollenverwaltung/redux/selectors';


const countries = [
    { value: "1", label: "Liste 52a 1.12" },
    { value: "2", label: "Nigeria" },
    { value: "3", label: "Kenya" },
    { value: "4", label: "South Africa" },
    { value: "5", label: "United States" },
    { value: "6", label: "Canada" },
    { value: "7", label: "Germany" }
  ];
  

const mapStateToProps = state => {
    return {
        users: getUserPage(state),
        user: getSingleUser(state),
        numberOfElements: getNumberOfElements(state),
        page: getPageNumber(state),
        attachments: getUserRoleAttachments(state),

        roles: getRolePage(state),

        userRoles: getUserRoles(state)
    }
}


function Userverwaltung(props) {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isEditUserOpen, onOpen: onEditUserOpen, onClose: onEditUserClose } = useDisclosure()
    const { isOpen: isDeleteUserOpen, onOpen: onDeleteUserOpen, onClose: onDeleteUserClose } = useDisclosure()
    const { isOpen: isRoleAttachmentOpen, onOpen: onRoleAttachmentOpen, onClose: onRoleAttachmentClose } = useDisclosure()

    const [loaded, setIsLoaded] = React.useState(false);
    const [usersLoaded, setUsersIsLoaded] = React.useState(false);
    const [users, setUsers] = React.useState([]);
    const [deleteBuffer, setDeleteBuffer] = React.useState(0);
    const [progressArr, setProgress] = React.useState([]);

    const [connection, setConnection] = React.useState(null);

    const [pickerItems, setPickerItems] = React.useState(countries);
    const [selectedItems, setSelectedItems] = React.useState([]);


    const [roleAttachmentUserId, setRoleAttachmentUserId] = React.useState(0);
    const [roleAttachments, setRoleAttachments] = React.useState([]);
    const [roleAttachmentPage, setRoleAttachmentPage] = React.useState(1);
    const numberOfElementsPerPage = 20; 


    const handleCreateItem = (item) => {
        setPickerItems((curr) => [...curr, item]);
        setSelectedItems((curr) => [...curr, item]);
    };

    const handleSelectedItemsChange = (selectedItems) => {
        if (selectedItems) {
        setSelectedItems(selectedItems);
        }
    };



    const onValueChange = (propName, value) => {
        const user = {...props.user};

        user[propName] = value;



        props.changeSingleUser(user);
    }

    const onEnvironmentChange = (propName, value) => {
        const user = {...props.user};

        const userEnvironment = {...user.userEnvironment};
        userEnvironment[propName] = value;


        user.userEnvironment = userEnvironment;
        props.changeSingleUser(user);
    }

    const editUser = (id) => {
        onEditUserOpen();
        props.getSingleUserFromServer(id);
    }

    const deleteUser = (id) => {
        setDeleteBuffer(id);
        onDeleteUserOpen()
    }

    const deleteUserOk = () => {
        props.deleteUser(deleteBuffer);
        onDeleteUserClose();
    }

    const attachRoles = async (userId) => {
        props.clearAttachments();
        props.clearRoles();
        await onRoleAttachmentOpen();
        setRoleAttachmentUserId(userId);
        props.getRolePageISFromServer(roleAttachmentPage, numberOfElementsPerPage)
    }

    const changeUserAttachment = (roleId, isChecked) => {
        var attachment = {roleId, isAttached: isChecked};
       

        props.appendeUserRoleAttachments(attachment);

    }

    const saveAttachments = () => {
        var attachmentList = [];
        var detachmentList = [];


        props.attachments.filter(attachment => attachment.isAttached === true).map((at) => {
            attachmentList.push({roleId: at.roleId});
        });
        props.attachments.filter(attachment => attachment.isAttached === false).map((at) => {
            detachmentList.push({roleId: at.roleId});
        });

        
        props.bulkAttachRoles(roleAttachmentUserId, attachmentList);
        props.bulkDetachRoles(roleAttachmentUserId, detachmentList);

        onRoleAttachmentClose();
    }

    const saveFile = (link) => {
        api.get(link, { responseType: 'blob' }).then((response) => {
            let fileName = "file.zip";
            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                window.navigator.msSaveOrOpenBlob(new Blob([response.data],
                        { type: 'application/zip' }
                    ),
                    fileName
                );
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data],
                    { type: 'application/zip' }));
                const link = role.createElement('a');
                link.href = url;
                link.setAttribute('download', "dokumente.zip");
                role.body.appendChild(link);
                link.click();
            }
        })
    }


    React.useEffect(() => {
        if(!loaded)
        {
            props.getUserPageFromServer(1,10);    
            setIsLoaded(true);
        }
            var usersLoc = []
            props.users.values.map((user) => {
                var userLoc = {
                    dbId: user.dbId,
                    email: user.email,
                }
                usersLoc.push(userLoc);
            })
            setUsers(usersLoc);
            setUsersIsLoaded(true);
        
    }, [props.users])

    React.useEffect(() => {
        props.getUserPageFromServer(props.page, props.numberOfElements);    
    }, [props.numberOfElements, props.page]);


    React.useEffect(() => {
        var ids = [];
        props.roles.values.map((role) => {
            ids.push({roleId: role.dbId});
        });
        
        if(ids.length !== 0)
        {
            props.hasRolesOnServer(roleAttachmentUserId, ids);
        }
    }, [props.roles]);

    React.useEffect(() => {
        var roleAttachments = [];
            props.roles.values.map((role) => {

                var userRole = props.userRoles.filter(cD => cD.roleId === role.dbId);
                var attachment = props.attachments.filter(a => a.roleId === role.dbId);

                var attached = attachment.length !== 0 ? attachment[0].isAttached : false;
                var userRoleAttached = userRole.length !== 0 ? userRole[0].isAttached : false;


                var isAttachedToUser = attachment.length !== 0 ? attached : userRoleAttached || role.isGlobalRole;
                

                var roleAttachment = {
                    dbId: role.dbId,
                    isAttachedToUser: isAttachedToUser,
                    role_name: role.role_name,
                }
                roleAttachments.push(roleAttachment);
            })
            setRoleAttachments(roleAttachments);
    }, [props.roles, props.userRoles, props.attachments]);


    React.useEffect(() => {
         
        
        var usersLoc = [...users];
                      
        
        


        progressArr.map((pv,pk) => {
            usersLoc.filter(c => c.dbId == pk).map(c => {
                c.zipFileName = pv;
            });
        });
                        
        
        
        setUsers(usersLoc);
    }, [progressArr])



    const data = React.useMemo(
        () => users,
        [],
    )

    const columns = React.useMemo(
        () => [
            {
                // Build our expander column
                accessor: "roles",
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                    <span {...getToggleAllRowsExpandedProps()}>
                      {isAllRowsExpanded ? <VscTriangleDown /> : <VscTriangleRight />}
                    </span>
                  ),
                Cell: ({ row }) => (
                    // Use Cell to render an expander for each row.
                    // We can use the getToggleRowExpandedProps prop-getter
                    // to build the expander.
                    <span {...row.getToggleRowExpandedProps()}>
                      {row.isExpanded ? <VscTriangleDown /> : <VscTriangleRight />}
                    </span>
                  ),
              },
            {
                Header: 'ID',
                accessor: 'dbId'
            },
            {
                Header: 'Benutzernname',
                accessor: 'email',
            },
            {
                Header: 'Funktionen',
                accessor: 'functions',
                Cell: ({ row }) => {
                    return <div>
                        <ButtonGroup>
                            <Button onClick={() => editUser(row.values.dbId)} colorScheme='gray' leftIcon={<FiEdit2 />}>Bearbeiten</Button>
                            <Button onClick={() => deleteUser(row.values.dbId)} colorScheme='gray' leftIcon={<AiFillDelete />}>Löschen</Button>
                            <Button onClick={() => attachRoles(row.values.dbId)} colorScheme='gray' leftIcon={<FiFile />}>Rollen zuordnen</Button>
                        </ButtonGroup>
                    </div>
                }
            },
        ],
        [],
    )

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data }, useSortBy)

    const subColumns = React.useMemo(
        () => [
            {
                Header: "ID",
                accessor: 'dbId'
            },
            {
                Header: 'Ist Benutzern zugeordnet',
                accessor: 'isAttachedToUser',
                Cell: ({row}) => {
                    return <Checkbox isDisabled={row.values.isGlobalRole} onChange={(e) => changeUserAttachment(row.values.dbId, e.target.checked)} isChecked={row.values.isAttachedToUser}/>
                }
            },
            {
                Header: 'Rollenname',
                accessor: 'role_name',
            }
        ],
        [],
    );


        const renderRowSubComponent = React.useCallback(
            ({ row }) => {
                    console.log(role.active)
                    return <span></span>//<Datatable columns={subColumns} data={row.values.roles} />
            },
            []
          )

    return (
        <Box
            width={"100%"}
            height={'100%'}
            align="center"
            mb="4"
            borderRadius="lg"
            role="group"
            cursor="pointer" display={'flex'} flexDirection={"column"}>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Benutzer</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            <FormControl isRequired>
                                <FormLabel>Benutzernname</FormLabel>
                                <Input placeholder="Benutzernname" type="email" value={props.user.email} onChange={(e) => onValueChange("email", e.target.value)} />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Passwort</FormLabel>
                                <Input placeholder="Passwort" type="password" value={props.user.password} onChange={(e) => onValueChange("password", e.target.value)}/>
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Name</FormLabel>
                                <Input placeholder="Name" type="text" value={props.user.personName} onChange={(e) => onValueChange("personName", e.target.value)}/>
                            </FormControl>
                            <FormControl isRequired>
                                <Checkbox isChecked={props.user.changePasswordMail} onChange={(e) => onValueChange('changePasswordMail', e.target.checked)}>Sende Passwortändern Mail</Checkbox>
                            </FormControl>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost' onClick={() => props.createUser(props.user)}>Speichern</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isEditUserOpen} onClose={onEditUserClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Bearbeite Benutzer {props.user.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                        <FormControl isRequired>
                                <FormLabel>Benutzernname</FormLabel>
                                <Input placeholder="Benutzernname" type="email" value={props.user.email} onChange={(e) => onValueChange("email", e.target.value)} />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Passwort</FormLabel>
                                <Input placeholder="Passwort" type="password" value={props.user.password} onChange={(e) => onValueChange("password", e.target.value)}/>
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Name</FormLabel>
                                <Input placeholder="Name" type="text" value={props.user.personName} onChange={(e) => onValueChange("personName", e.target.value)}/>
                            </FormControl>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onEditUserClose}>
                            Close
                        </Button>
                        <Button variant='ghost' onClick={() => {props.updateUser(props.user.dbId, props.user); onEditUserClose()}}>Speichern</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal size={'xl'} isOpen={isRoleAttachmentOpen} onClose={onRoleAttachmentClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Rollenzuordnung</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody display={'flex'} flexDirection={"column"} bg={useColorModeValue('gray.100', 'gray.900')}>
                        <Box width={"100%"}
                            height={'600px'}
                            align="center"
                            mb="4"
                            borderRadius="lg"
                            role="group"
                            cursor="pointer" display={'flex'} flexDirection={"column"}>
                            <Box
                                overflow={'auto'}
                                flexDirection={'column'}
                                flexGrow={1}
                                display={'flex'}
                                mt="4"
                                bg={useColorModeValue('gray.50', 'gray.800')}>
                                    <Datatable useInfinityScroll={true} page={props.roles.page} pageCount={props.roles.pageCount} setPage={(page) => props.getRolePageISFromServer(page, numberOfElementsPerPage)} columns={subColumns} data={roleAttachments}/>
                            </Box>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onRoleAttachmentClose}>
                            Close
                        </Button>
                        <Button onClick={() => saveAttachments()} variant='ghost'>Speichern</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <AlertDialog
                isOpen={isDeleteUserOpen}
                onClose={onDeleteUserClose}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Benutzern löschen
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Soll der Benutzer wirklich gelöscht werden?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button onClick={onDeleteUserClose}>
                                Abbrechen
                            </Button>
                            <Button colorScheme='red' onClick={() => deleteUserOk()} ml={3}>
                                Löschen
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <Box
                p="4"
                bg={useColorModeValue('gray.50', 'gray.800')}>
                <HStack spacing='24px'>
                    <Box w={'full'}>
                    <HStack spacing='24px'>
                        <Select value={props.numberOfElements} w={'100px'} onChange={(e) => { props.changeNumberOfElements(e.target.value)}}>
                            <option  value={10}>10</option>
                            <option  value={50}>50</option>
                            <option  value={100}>100</option>
                        </Select>
                    </HStack>
                    </Box>
                    <Box w={'full'} textAlign={'right'}>
                    <ButtonGroup>
                        <Button colorScheme={"green"} leftIcon={<FiPlus />} onClick={onOpen}>Neuen Benutzern anlegen</Button>
                    </ButtonGroup>
                    </Box>
                </HStack>
            </Box>
            <Box
            overflow={'auto'}

                flexDirection={'column'}
                flexGrow={1}
                display={'flex'}
                mt="4"
                bg={useColorModeValue('gray.50', 'gray.800')}>
                <Datatable pageCount={props.users.pageCount} setPage={(page) => changeNumberOfPage(page)} loading={false} data={users} columns={columns} renderRowSubComponent={renderRowSubComponent}/>
            </Box>
        </Box>
    );
}

const mapDispatchToProps = {
    //UserActions
    changeSingleUser,
    getUserPageFromServer,
    getSingleUserFromServer,
    createUser,
    updateUser,
    deleteUser,
    changeNumberOfPage,
    changeNumberOfElements,
    generateRoles,
    hasRolesOnServer,
    appendeUserRoleAttachments,
    bulkAttachRoles,
    bulkDetachRoles,
    clearAttachments,
    clearRoles,

    getRolePageISFromServer

    //RoleActions
}


export default connect(mapStateToProps, mapDispatchToProps)(Userverwaltung);