import { CHANGE_SINGLE_AMT, CHANGE_AMT_PAGE , CHANGE_NUMBER_OF_ELEMENTS, CHANGE_PAGE } from "../actionType";



const initialState = {
    aemter: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },
    amt: {
        id: 0,
        bezeichnung: "",
    },
    numberOfElements: 10,
    page: 1,
    roleRights: [],
    attachments: []
};


const amtverwaltung = (state = initialState, action) => {
    switch(action.type) {
        case CHANGE_SINGLE_AMT: {
            return {...state, amt: action.payload}
        }
        case CHANGE_AMT_PAGE: {
            return {...state, aemter: action.payload}
        }
        case CHANGE_NUMBER_OF_ELEMENTS: {
            return {...state, numberOfElements: action.payload}
        }
        case CHANGE_PAGE: {
            return {...state, page: action.payload}
        }
        
        default:
            return state;
    }
}

export default amtverwaltung;