import { SHOW_MODAL_DIALOG, HIDE_MODAL_DIALOG } from '../actionTypes';

const initalState = {
    visible: false,
    modalType: '',
    modalProps: {}
};

const modalDialog = (state = initalState, action) => {
    switch (action.type) {
        case SHOW_MODAL_DIALOG:
            return {
                visible: true,
                modalType: action.payload.modalType,
                modalProps: action.payload.modalProps
            };
        case HIDE_MODAL_DIALOG:
            return {...state, visible: false };
        default:
            return state;
    }
};

export default modalDialog;