import { CHANGE_VERSION }from "../actionTypes";

const initialState = {
    version: ""
};


const settings = (state = initialState, action) => {
    switch(action.type) {
        case CHANGE_VERSION: {
            return {...state, version: action.payload}
        }
        default:
            return state;
    }
}

export default settings;