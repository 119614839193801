import { CHANGE_RIGHT_PAGE, CHANGE_ROLE_DOCUMENTS, APPEND_ROLE_RIGHT_ATTACHMENTS, CHANGE_ROLE_PAGE, CHANGE_NUMBER_OF_ELEMENTS, CHANGE_PAGE, CHANGE_SINGLE_ROLE, REMOVE_ROLE_DOCUMENT_ATTACHMENTS, CLEAR_ATTACHMENTS, CHANGE_RIGHT_PAGE_PUSH, CHANGE_ROLE_PAGE_PUSH, CLEAR_RIGHTS, CLEAR_ROLES } from './actionTypes';
import { getJsonDataFromApi, addDataToApi, changeDataOnApi, deleteIdFromApi } from '../../../../services/api'



export const changePage = (pageData) => ({
    type: CHANGE_ROLE_PAGE,
    payload: pageData
});

export const changePagePush = (pageData) => ({
    type: CHANGE_ROLE_PAGE_PUSH,
    payload: pageData
})

export const changeRightsPage = (pageData) => ({
    type: CHANGE_RIGHT_PAGE,
    payload: pageData
})

export const changeRightsPagePush = (pageData) => ({
    type: CHANGE_RIGHT_PAGE_PUSH,
    payload: pageData
})

export const changeSingleRole = (pageData) => ({
    type: CHANGE_SINGLE_ROLE,
    payload: pageData
});

export const changeNumberOfElements = (numberOfElements) => ({
    type: CHANGE_NUMBER_OF_ELEMENTS,
    payload: numberOfElements
});

export const changeNumberOfPage = (pageNumber) => ({
    type: CHANGE_PAGE,
    payload: pageNumber
});

export const changeRoleRights = (roleRights) => ({
    type: CHANGE_ROLE_DOCUMENTS,
    payload: roleRights
});

export const appendeRoleRightAttachments = (rightAttachment) => {
    return {
        type: APPEND_ROLE_RIGHT_ATTACHMENTS,
        payload: rightAttachment
    }
};
export const removeRoleRightAttachments = (rightAttachment) => {
    return {
        type: REMOVE_ROLE_DOCUMENT_ATTACHMENTS,
        payload: rightAttachments
    }
};

export const clearAttachments = () => {
    return {
        type: CLEAR_ATTACHMENTS,
    }
};

export const clearRoles = () => {
    return {
        type: CLEAR_ROLES,
    }
};

export const clearRights = () => {
    return {
        type: CLEAR_RIGHTS
    }
};


export const getSingleRoleFromServer = (id) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/role/${id}`,
            successAction: changeSingleRole,
            loadingMsg:'Lade Kundendaten vom Server'
        };
        await getJsonDataFromApi(config);
    };
};

export const getRightPageFromServer = (page, pageSize) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/rights?PageNumber=${page}&PageSize=${pageSize}`,
            successAction: changeRightsPagePush,
            loadingMsg:'Lade Rechte vom Server'
        };
        await getJsonDataFromApi(config);
    };
};

export const getRolePageFromServer = (page, pageSize) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/roles?PageNumber=${page}&PageSize=${pageSize}`,
            successAction: changePage,
            loadingMsg:'Lade Rollen vom Server'
        };
        await getJsonDataFromApi(config);
    };
};
export const getRolePageISFromServer = (page, pageSize) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/roles?PageNumber=${page}&PageSize=${pageSize}`,
            successAction: changePagePush,
            loadingMsg:'Lade Rollen vom Server'
        };
        await getJsonDataFromApi(config);
    };
};

export const createRole = (role) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: 'api/v1/role',
            data: role,
            successAction: () => getRolePageFromServer(1, 50),
            loadingMsg:'Erstelle Kunden auf dem Server'
        };
        await addDataToApi(config);
    };
};

export const updateRole = (id, role) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/role/${id}`,
            data: role,
            successAction: (data) => {getRolePageFromServer(1, 50); changeSingleRole(data)},
            loadingMsg:'Update Kundendaten auf dem Server'
        };
        await changeDataOnApi(config);
    };
}

export const deleteRole = (id) => {
    return async (dispatch) =>  {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/role/${id}`,
            successAction: () => getRolePageFromServer(1, 50),
            loadingMsg: 'Lösche Kundendaten vom Server'
        }
        await deleteIdFromApi(config);
    }
}

export const hasRightsOnServer = (roleId, rights) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/role/${roleId}/hasRights`,
            data: rights,
            successAction: changeRoleRights,
            loadingMsg: 'Lade Rollen vom Server'
        }
        await addDataToApi(config);
    }
}

export const bulkAttachRights = (roleId, attachments) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/role/${roleId}/bulkAttachRights`,
            data: attachments,
            successAction: (data) => {getRolePageFromServer(1, 50);},
            loadingMsg:'Füge Rechte einer Rolle hinzu'
        };
        await changeDataOnApi(config);
    };
};

export const bulkDetachRights = (roleId, attachments) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/role/${roleId}/bulkRemoveRights`,
            data: attachments,
            successAction: (data) => {getRolePageFromServer(1, 50);},
            loadingMsg:'Entferne Rechte vom einer Rolle'
        };
        await changeDataOnApi(config);
    };
};