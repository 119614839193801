import { SHOW_LOADING_OVERLAY, HIDE_LOADING_OVERLAY } from '../actionTypes';

const initalState = {
    loading: false,
    message: ''
};

const loadingOverlay = (state = initalState, action) => {
    switch (action.type) {
        case SHOW_LOADING_OVERLAY:
            {
                const message = action.payload ? action.payload : '';
                return { loading: true, message: message };
            }
        case HIDE_LOADING_OVERLAY:
            return { loading: false, message: '' };
        default:
            return state;
    }
};

export default loadingOverlay;