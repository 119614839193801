import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getModalState } from './redux/selectors';
import { hideModal } from './redux/actions';

import { useToast } from '@chakra-ui/react';

const mapStateToProps = state => {
    const modalState = getModalState(state);
    return {
        visible: modalState.visible,
        modalType: modalState.modalType,
        modalProps: modalState.modalProps
    };
};



function ModalDialogRoot(props) {

    const toast = useToast();


    return <div>
        {
            props.visible &&
            toast({
                title: "Ein Fehler ist aufgetreten",
                description: props.modalProps.message,
                status: 'error',
                duration: 5000,
                isClosable: true
            }) && ""
        }
    </div>;
};

const mapDispatchToProps = {
    hideModal
};

ModalDialogRoot.propTypes = {
    visible: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    modalType: PropTypes.string.isRequired,
    modalProps: PropTypes.shape({
        message: PropTypes.string,
        yesAction: PropTypes.func,
        noAction: PropTypes.func
    })
};

export default connect(mapStateToProps,mapDispatchToProps)(ModalDialogRoot);