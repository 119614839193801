import { CHANGE_VERSION } from './actionTypes';
import { getJsonDataFromApi, addDataToApi, changeDataOnApi, deleteIdFromApi } from '../../../../services/api'



export const changeVersion = (version) => ({
    type: CHANGE_VERSION,
    payload: version
});

export const getVersionFromServer = () => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/version`,
            successAction: (data) => changeVersion(data.version),
            loadingMsg:'Lade Version vom Server'
        };
        await getJsonDataFromApi(config);
    };
};