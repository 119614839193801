import { CHANGE_SINGLE_EXPORTVORLAGE, CHANGE_EXPORTVORLAGE_PAGE, CHANGE_NUMBER_OF_ELEMENTS, CHANGE_PAGE } from '../actionTypes';


const initialState = {
    exportvorlagen: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },
    exportvorlage: {
        id: 0,
        templateName: "",
    },
    numberOfElements: 10,
    page: 1,
   
};


const exportverwaltung = (state = initialState, action) => {
    switch(action.type) {
        case CHANGE_SINGLE_EXPORTVORLAGE: {
            return {...state, exportvorlage: action.payload}
        }
        case CHANGE_EXPORTVORLAGE_PAGE: {
            return {...state, exportvorlagen: action.payload}
        }
        case CHANGE_NUMBER_OF_ELEMENTS: {
            return {...state, numberOfElements: action.payload}
        }
        case CHANGE_PAGE: {
            return {...state, page: action.payload}
        }
        
        default:
            return state;
    }
}

export default exportverwaltung;