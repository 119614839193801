import React, {useState} from 'react';
import {Box,FormControl, Button} from '@chakra-ui/react';
import FileUpload from '../../common/FileUpload';
import { connect } from 'react-redux';
import { createVersion, uploadFile }  from './redux/actions';

const mapStateToProps = state => {
    return {
    
    }
}

function FileUploadPage(props){
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);

    const [documentFile, setDocumentFile] = React.useState({name: ""});

    const createDocument = () => {

      

        const formData = new FormData();
       
        formData.append('file', documentFile);


        props.uploadFile(formData);
        //props.createVersion(selectedDocument, formData, props.page, props.numberOfElements);
    }

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
	};

	const handleSubmission = () => {
	};

	return(
//    <div>
// 			<input type="file" name="file" onChange={changeHandler} />
// 			<div>
// 				<button onClick={handleSubmission}>Submit</button>
// 			</div>
// 		</div>
        <Box>

        <FormControl isRequired>
            <FileUpload value={documentFile.name} onChange={(document) => setDocumentFile(document)} name="dokument" placeholder={"Dokument"} acceptedFileTypes={["xlsx", "xls"]}>GesamtListe hochladen</FileUpload>
        </FormControl>
        <Button colorScheme={"green"} onClick={() => createDocument(documentFile)} variant='ghost'>Speichern</Button>
    </Box>
	)

}
const mapDispatchToProps = {
    
    createVersion,
    uploadFile
}

export default connect(mapStateToProps, mapDispatchToProps) (FileUploadPage)