import { CHANGE_DOCUMENT  } from './actionTypes';
import { addDataToApi, addFormDataToApi } from '../../../../services/api'

export const changeDocument = (document) => {
    return {
        type: CHANGE_DOCUMENT,
        payload: document
    }
}

export const uploadFile = (fdata) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: 'api/v2/UploadList',
            formData: fdata,
            successAction: () => {},
            loadingMsg:'Erstelle Dokument auf dem Server'
        };
        await addFormDataToApi(config);
    };
};

export const createDocument = (document) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: 'api/v2/UploadList',
            data: document,
            successAction: () => {},
            loadingMsg:'Erstelle Dokument auf dem Server'
        };
        await addDataToApi(config);
    };
};

export const createVersion = (id, documentVersion, page, pageCount) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/document/${id}/version`,
            formData: documentVersion,
            successAction: () => getDocumentsPageFromServer(page, pageCount),
            loadingMsg: 'Erstelle neue Version auf dem Server'
        }
        await addFormDataToApi(config);
    };
};