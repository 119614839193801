import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    AlertDialog,
    AlertDialogHeader,
    AlertDialogFooter,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogBody,
    Box,
    Select,
    Button,
    ButtonGroup,
    Progress,
    Input,
    useColorModeValue,
    useDisclosure,
    FormControl,
    FormLabel,
    Checkbox,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    HStack
} from '@chakra-ui/react';
import { useTable, useSortBy } from 'react-table'
import Datatable from '../../common/Datatable';
import { FiPlus, FiEdit2, FiFile } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import { VscTriangleDown, VscTriangleRight } from 'react-icons/vsc';
import { getRightPage, getRoleRightAttachments, getRolePage, getNumberOfElements, getPageNumber, getSingleRole, getRoleRights } from './redux/selectors';
import { getRightPageFromServer, changeSingleRole, getSingleRoleFromServer, getRolePageFromServer, createRole, updateRole, changeNumberOfElements, changeNumberOfPage, deleteRole, hasRightsOnServer, appendeRoleRightAttachments, bulkAttachRights, bulkDetachRights, clearAttachments, clearRights} from './redux/actions';
import { connect } from 'react-redux';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { token, url } from '../../../services/api';
import api from '../../../services/api';


const countries = [
    { value: "1", label: "Liste 52a 1.12" },
    { value: "2", label: "Nigeria" },
    { value: "3", label: "Kenya" },
    { value: "4", label: "South Africa" },
    { value: "5", label: "United States" },
    { value: "6", label: "Canada" },
    { value: "7", label: "Germany" }
  ];
  

const mapStateToProps = state => {
    return {
        roles: getRolePage(state),
        role: getSingleRole(state),
        numberOfElements: getNumberOfElements(state),
        page: getPageNumber(state),
        attachments: getRoleRightAttachments(state),

        rights: getRightPage(state),

        roleRights: getRoleRights(state)
    }
}


function Rollenverwaltung(props) {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isEditRoleOpen, onOpen: onEditRoleOpen, onClose: onEditRoleClose } = useDisclosure()
    const { isOpen: isDeleteRoleOpen, onOpen: onDeleteRoleOpen, onClose: onDeleteRoleClose } = useDisclosure()
    const { isOpen: isRoleAttachmentOpen, onOpen: onRoleAttachmentOpen, onClose: onRoleAttachmentClose } = useDisclosure()

    const [loaded, setIsLoaded] = React.useState(false);
    const [roleLoaded, setRoleIsLoaded] = React.useState(false);
    const [roles, setRoles] = React.useState([]);
    const [deleteBuffer, setDeleteBuffer] = React.useState(0);
    const [progressArr, setProgress] = React.useState([]);

    const [connection, setConnection] = React.useState(null);

    const [pickerItems, setPickerItems] = React.useState(countries);
    const [selectedItems, setSelectedItems] = React.useState([]);

    const [userAttachmentRoleId, setUserAttachmentRoleId] = React.useState(0);
    const [userAttachments, setUserAttachments] = React.useState([]);
    const [userAttachmentPage, setUserAttachmentPage] = React.useState(1);
    const numberOfElementsPerPage = 20; 


    const handleCreateItem = (item) => {
        setPickerItems((curr) => [...curr, item]);
        setSelectedItems((curr) => [...curr, item]);
    };

    const handleSelectedItemsChange = (selectedItems) => {
        if (selectedItems) {
        setSelectedItems(selectedItems);
        }
    };



    const onValueChange = (propName, value) => {
        const role = {...props.role};

        role[propName] = value;



        props.changeSingleRole(role);
    }

    const onEnvironmentChange = (propName, value) => {
        const role = {...props.role};

        const roleEnvironment = {...role.roleEnvironment};
        roleEnvironment[propName] = value;


        role.roleEnvironment = roleEnvironment;
        props.changeSingleRole(role);
    }

    const editRole = (id) => {
        onEditRoleOpen();
        props.getSingleRoleFromServer(id);
    }

    const deleteRole = (id) => {
        setDeleteBuffer(id);
        onDeleteRoleOpen()
    }

    const deleteRoleOk = () => {
        props.deleteRole(deleteBuffer);
        onDeleteRoleClose();
    }

    const attachRights = async (roleId) => {
        props.clearAttachments();
        props.clearRights();
        await onRoleAttachmentOpen();
        setUserAttachmentRoleId(roleId);
        props.getRightPageFromServer(userAttachmentPage, numberOfElementsPerPage)
    }

    const changeRoleAttachment = (rightId, isChecked) => {
        var attachment = {rightId, isAttached: isChecked};
       

        props.appendeRoleRightAttachments(attachment);

    }

    const saveAttachments = () => {
        var attachmentList = [];
        var detachmentList = [];


        props.attachments.filter(attachment => attachment.isAttached === true).map((at) => {
            attachmentList.push({rightId: at.rightId});
        });
        props.attachments.filter(attachment => attachment.isAttached === false).map((at) => {
            detachmentList.push({rightId: at.rightId});
        });

        
        props.bulkAttachRights(userAttachmentRoleId, attachmentList);
        props.bulkDetachRights(userAttachmentRoleId, detachmentList);

        onRoleAttachmentClose();
    }

    const saveFile = (link) => {
        api.get(link, { responseType: 'blob' }).then((response) => {
            let fileName = "file.zip";
            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                window.navigator.msSaveOrOpenBlob(new Blob([response.data],
                        { type: 'application/zip' }
                    ),
                    fileName
                );
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data],
                    { type: 'application/zip' }));
                const link = right.createElement('a');
                link.href = url;
                link.setAttribute('download', "dokumente.zip");
                right.body.appendChild(link);
                link.click();
            }
        })
    }


    React.useEffect(() => {
        if(!loaded)
        {
            props.getRolePageFromServer(1,10);    
            setIsLoaded(true);
        }
            var rolesLoc = []
            props.roles.values.map((role) => {
                var roleLoc = {
                    dbId: role.dbId,
                    role_name: role.role_name,
                }
                rolesLoc.push(roleLoc);
            })
            setRoles(rolesLoc);
            setRoleIsLoaded(true);
        
    }, [props.roles])

    React.useEffect(() => {
        props.getRolePageFromServer(props.page, props.numberOfElements);    
    }, [props.numberOfElements, props.page]);


    React.useEffect(() => {
        var ids = [];
        props.rights.values.map((right) => {
            ids.push({rightId: right.dbId});
        });
        if(ids.length !== 0)
        {
            props.hasRightsOnServer(userAttachmentRoleId, ids);
        }
    }, [props.rights]);

    React.useEffect(() => {
        var rightAttachments = [];
            props.rights.values.map((right) => {

                var roleRight = props.roleRights.filter(cD => cD.rightId === right.dbId);
                var attachment = props.attachments.filter(a => a.rightId === right.dbId);

                var attached = attachment.length !== 0 ? attachment[0].isAttached : false;
                var roleRightAttached = roleRight.length !== 0 ? roleRight[0].isAttached : false;


                var isAttachedToRole = attachment.length !== 0 ? attached : roleRightAttached || right.isGlobalRight;
                

                var rightAttachment = {
                    dbId: right.dbId,
                    right_name: right.right_name,
                    isAttachedToRole: isAttachedToRole
                }
                rightAttachments.push(rightAttachment);
            })
            setUserAttachments(rightAttachments);
    }, [props.rights, props.roleRights, props.attachments]);


    React.useEffect(() => {
        
        
        var rolesLoc = [...roles];
                      
        
        


        progressArr.map((pv,pk) => {
            rolesLoc.filter(c => c.dbId == pk).map(c => {
                c.zipFileName = pv;
            });
        });
                        
        
        setRoles(rolesLoc);
    }, [progressArr])



    const data = React.useMemo(
        () => roles,
        [],
    )

    const columns = React.useMemo(
        () => [
            {
                // Build our expander column
                accessor: "rights",
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                    <span {...getToggleAllRowsExpandedProps()}>
                      {isAllRowsExpanded ? <VscTriangleDown /> : <VscTriangleRight />}
                    </span>
                  ),
                Cell: ({ row }) => (
                    // Use Cell to render an expander for each row.
                    // We can use the getToggleRowExpandedProps prop-getter
                    // to build the expander.
                    <span {...row.getToggleRowExpandedProps()}>
                      {row.isExpanded ? <VscTriangleDown /> : <VscTriangleRight />}
                    </span>
                  ),
              },
            {
                Header: 'ID',
                accessor: 'dbId'
            },
            {
                Header: 'Rollenname',
                accessor: 'role_name',
            },
            {
                Header: 'Funktionen',
                accessor: 'functions',
                Cell: ({ row }) => {
                    return <div>
                        <ButtonGroup>
                            <Button onClick={() => editRole(row.values.dbId)} colorScheme='gray' leftIcon={<FiEdit2 />}>Bearbeiten</Button>
                            <Button onClick={() => deleteRole(row.values.dbId)} colorScheme='gray' leftIcon={<AiFillDelete />}>Löschen</Button>
                            <Button onClick={() => attachRights(row.values.dbId)} colorScheme='gray' leftIcon={<FiFile />}>Rechte zuordnen</Button>
                        </ButtonGroup>
                    </div>
                }
            },
        ],
        [],
    )

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data }, useSortBy)

    const subColumns = React.useMemo(
        () => [
            {
                Header: "ID",
                accessor: 'dbId'
            },
            {
                Header: 'Ist Rollen zugeordnet',
                accessor: 'isAttachedToRole',
                Cell: ({row}) => {
                    return <Checkbox isDisabled={row.values.isGlobalRight} onChange={(e) => changeRoleAttachment(row.values.dbId, e.target.checked)} isChecked={row.values.isAttachedToRole}/>
                }
            },
            {
                Header: 'Rechtename',
                accessor: 'right_name',
            },
        ],
        [],
    );


        const renderRowSubComponent = React.useCallback(
            ({ row }) => {
                    
                    return <span></span>//<Datatable columns={subColumns} data={row.values.rights} />
            },
            []
          )

    return (
        <Box
            width={"100%"}
            height={'100%'}
            align="center"
            mb="4"
            borderRadius="lg"
            role="group"
            cursor="pointer" display={'flex'} flexDirection={"column"}>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Rolle</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            <FormControl isRequired>
                                <FormLabel>Rollenname</FormLabel>
                                <Input placeholder="Rollenname" type="text" value={props.role.role_name} onChange={(e) => onValueChange("role_name", e.target.value)} />
                            </FormControl>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost' onClick={() => props.createRole(props.role)}>Speichern</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isEditRoleOpen} onClose={onEditRoleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Bearbeite Rolle {props.role.role_name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            <FormControl isRequired>
                                <FormLabel>Rollenname</FormLabel>
                                <Input placeholder="Rollenname" type="text" value={props.role.role_name} onChange={(e) => onValueChange("role_name", e.target.value)} />
                            </FormControl>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onEditRoleClose}>
                            Close
                        </Button>
                        <Button variant='ghost' onClick={() => {props.updateRole(props.role.dbId, props.role); onEditRoleClose()}}>Speichern</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal size={'xl'} isOpen={isRoleAttachmentOpen} onClose={onRoleAttachmentClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Dokumenten zuordnung</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody display={'flex'} flexDirection={"column"} bg={useColorModeValue('gray.100', 'gray.900')}>
                        <Box width={"100%"}
                            height={'600px'}
                            align="center"
                            mb="4"
                            borderRadius="lg"
                            role="group"
                            cursor="pointer" display={'flex'} flexDirection={"column"}>
                            <Box
                                overflow={'auto'}
                                flexDirection={'column'}
                                flexGrow={1}
                                display={'flex'}
                                mt="4"
                                bg={useColorModeValue('gray.50', 'gray.800')}>
                                    <Datatable useInfinityScroll={true} page={props.rights.page} pageCount={props.rights.pageCount} setPage={(page) => props.getRightPageFromServer(page, numberOfElementsPerPage)} columns={subColumns} data={userAttachments}/>
                            </Box>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onRoleAttachmentClose}>
                            Close
                        </Button>
                        <Button onClick={() => saveAttachments()} variant='ghost'>Speichern</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <AlertDialog
                isOpen={isDeleteRoleOpen}
                onClose={onDeleteRoleClose}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Rollen löschen
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Soll der Rolle wirklich gelöscht werden?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button onClick={onDeleteRoleClose}>
                                Abbrechen
                            </Button>
                            <Button colorScheme='red' onClick={() => deleteRoleOk()} ml={3}>
                                Löschen
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <Box
                p="4"
                bg={useColorModeValue('gray.50', 'gray.800')}>
                <HStack spacing='24px'>
                    <Box w={'full'}>
                    <HStack spacing='24px'>
                        <Select value={props.numberOfElements} w={'100px'} onChange={(e) => {props.changeNumberOfElements(e.target.value)}}>
                            <option  value={10}>10</option>
                            <option  value={50}>50</option>
                            <option  value={100}>100</option>
                        </Select>
                    </HStack>
                    </Box>
                    <Box w={'full'} textAlign={'right'}>
                    <ButtonGroup>
                        <Button colorScheme={"green"} leftIcon={<FiPlus />} onClick={onOpen}>Neuen Rollen anlegen</Button>
                    </ButtonGroup>
                    </Box>
                </HStack>
            </Box>
            <Box
            overflow={'auto'}

                flexDirection={'column'}
                flexGrow={1}
                display={'flex'}
                mt="4"
                bg={useColorModeValue('gray.50', 'gray.800')}>
                <Datatable pageCount={props.roles.pageCount} setPage={(page) => changeNumberOfPage(page)} loading={false} data={roles} columns={columns} renderRowSubComponent={renderRowSubComponent}/>
            </Box>
        </Box>
    );
}

const mapDispatchToProps = {
    //RoleActions
    changeSingleRole,
    getRolePageFromServer,
    getSingleRoleFromServer,
    createRole,
    updateRole,
    deleteRole,
    changeNumberOfPage,
    changeNumberOfElements,
    hasRightsOnServer,
    appendeRoleRightAttachments,
    bulkAttachRights,
    bulkDetachRights,
    clearAttachments,
    clearRights,

    //RightActions
    getRightPageFromServer
}


export default connect(mapStateToProps, mapDispatchToProps)(Rollenverwaltung);