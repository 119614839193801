import { CHANGE_USER_ROLES, APPEND_USER_ROLE_ATTACHMENTS, CHANGE_USER_PAGE, CHANGE_NUMBER_OF_ELEMENTS, CHANGE_PAGE, CHANGE_SINGLE_USER, REMOVE_USER_ROLE_ATTACHMENTS, CLEAR_ATTACHMENTS } from './actionTypes';
import { getJsonDataFromApi, addDataToApi, changeDataOnApi, deleteIdFromApi } from '../../../../services/api'



export const changePage = (pageData) => ({
    type: CHANGE_USER_PAGE,
    payload: pageData
});

export const changeSingleUser = (pageData) => ({
    type: CHANGE_SINGLE_USER,
    payload: pageData
});

export const changeNumberOfElements = (numberOfElements) => ({
    type: CHANGE_NUMBER_OF_ELEMENTS,
    payload: numberOfElements
});

export const changeNumberOfPage = (pageNumber) => ({
    type: CHANGE_PAGE,
    payload: pageNumber
});

export const changeUserRoles = (userRoles) => ({
    type: CHANGE_USER_ROLES,
    payload: userRoles
});

export const appendeUserRoleAttachments = (documentAttachment) => {
    return {
        type: APPEND_USER_ROLE_ATTACHMENTS,
        payload: documentAttachment
    }
};
export const removeUserRoleAttachments = (documentAttachment) => {
    return {
        type: REMOVE_USER_ROLE_ATTACHMENTS,
        payload: documentAttachments
    }
};

export const clearAttachments = () => {
    return {
        type: CLEAR_ATTACHMENTS,
    }
};





export const getSingleUserFromServer = (id) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/user/${id}`,
            successAction: changeSingleUser,
            loadingMsg:'Lade Benutzerdaten vom Server'
        };
        await getJsonDataFromApi(config);
    };
};

export const getAllUsersFromServer = () => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/users/all`,
            successAction: changePage,
            loadingMsg:'Lade Benutzerdaten vom Server'
        };
        await getJsonDataFromApi(config);
    };
};


export const getUserPageFromServer = (page, pageSize) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/users?PageNumber=${page}&PageSize=${pageSize}`,
            successAction: changePage,
            loadingMsg:'Lade Benutzerdaten vom Server'
        };
        await getJsonDataFromApi(config);
    };
};

export const createUser = (user) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: 'api/v1/user',
            data: user,
            successAction: () => getUserPageFromServer(1, 50),
            loadingMsg:'Erstelle Benutzer auf dem Server'
        };
        await addDataToApi(config);
    };
};

export const updateUser = (id, user) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/user/${id}`,
            data: user,
            successAction: (data) => {getUserPageFromServer(1, 50); changeSingleUser(data)},
            loadingMsg:'Update Benutzerdaten auf dem Server'
        };
        await changeDataOnApi(config);
    };
}

export const deleteUser = (id) => {
    return async (dispatch) =>  {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/user/${id}`,
            successAction: () => getUserPageFromServer(1, 50),
            loadingMsg: 'Lösche Benutzerdaten vom Server'
        }
        await deleteIdFromApi(config);
    }
}

export const hasRolesOnServer = (userId, documents) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/user/${userId}/hasRoles`,
            data: documents,
            successAction: changeUserRoles,
            loadingMsg: 'Lade Benutzerdokumente vom Server'
        }
        await addDataToApi(config);
    }
}

export const bulkAttachRoles = (userId, attachments) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/user/${userId}/bulkAttachRole`,
            data: attachments,
            successAction: (data) => {getUserPageFromServer(1, 50);},
            loadingMsg:'Füge Dokumente Benutzer hinzu'
        };
        await changeDataOnApi(config);
    };
};

export const bulkDetachRoles = (userId, attachments) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/user/${userId}/bulkDetachRole`,
            data: attachments,
            successAction: (data) => {getUserPageFromServer(1, 50);},
            loadingMsg:'Entferne Dokumente vom Benutzer'
        };
        await changeDataOnApi(config);
    };
};

export const generateRoles = (id) => {
    return async (dispatch) => {
        const config = {
            dispatch,
            apiEndpoint: `api/v1/user/${id}/generateRoles`,
            data: null,
            successAction: () => {},
            errorAction: () => {},
            loadingMsg:'Erstelle Dokumente für Benutzer'
        };
        await addDataToApi(config);
    };
}