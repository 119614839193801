import { CHANGE_SINGLE_FUNKTION, CHANGE_FUNKTION_PAGE , CHANGE_NUMBER_OF_ELEMENTS, CHANGE_PAGE } from "../actionTypes";

const initialState = {
    funktions: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },
    funktion: {
        id: 0,
        bezeichnung: "",
    },
    numberOfElements: 10,
    page: 1,
    roleRights: [],
    attachments: []
};

function funktionverwaltung(state = initialState, action) {
    switch(action.type) {
        case CHANGE_SINGLE_FUNKTION: {
            return {...state, funktion: action.payload}
        }
        case CHANGE_FUNKTION_PAGE: {
            return {...state, funktions: action.payload}
        }
        case CHANGE_NUMBER_OF_ELEMENTS: {
            return {...state, numberOfElements: action.payload}
        }
        case CHANGE_PAGE: {
            return {...state, page: action.payload}
        }
        
        default:
            return state;
    }
}

export default funktionverwaltung;