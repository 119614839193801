import React, { useEffect, useRef } from 'react';
import {
    Flex,
    Box,
    Fade,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    chakra,
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { useTable, useSortBy, useExpanded } from 'react-table'
import Pagination from './Pagination';
import './Datatable.css';
import Loading from './Loading/Loading';
import { isLoadingVisible } from './Loading/redux/selector';
import { connect } from 'react-redux';


const mapStateTpProps = state =>  {
    return {
        loading: isLoadingVisible(state)
    }
}

function Datatable({useInfinityScroll = false, ...props}) {


    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns, state: { expanded }, } =
        useTable({ columns: props.columns, data: props.data }, useSortBy, useExpanded)

    const [page, setPage] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(1);
    const [bottom, setBottom] = React.useState(false);
    const [isIdVisible, setIsIdVisible] = React.useState(false);


    const loadingRef = useRef(null);

    var options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0
    };


    const handleScroll = (e) => {
        if(e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight)
        {
            
            
            
            
            setBottom(true);
        }
    }
    
    useEffect(() => {
        
    }), [props.isIdVisible]

    useEffect(() => {
        setPageCount(props.pageCount)
    }, [props.pageCount]);

    useEffect(() => {
        setPage(props.page)
    }, [props.page]);

    useEffect(() => {
        if(page !== props.page){
            props.setPage(page);
        }
    }, [page]);


    useEffect(() => {
        if(useInfinityScroll)
        {
            const element = loadingRef.current;
            element.addEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        if(bottom) {
            if(page + 1 <= pageCount)
            {
                setPage(page + 1);
                setBottom(false);
            }   
        }
    }, [bottom])


    return (
        
        <Box flexGrow={1} display={'flex'} flexDirection={'column'} >
            <Box position={'relative'} flexGrow={1} overflow={'auto'} ref={r => (loadingRef.current = r)}>
                <Loading message={'Lade Daten...'}/>
                <Fade in={!props.loading}><Table {...getTableProps()} colorScheme='purple' position={'absolute'}>
                    <Thead>
                        {headerGroups.map((headerGroup) => (
                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, index) => (
                                    <Th
                                        key={`head_${index}`}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        isNumeric={column.isNumeric}
                                    >
                                        {column.render('Header')}
                                        <chakra.span pl='4'>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <TriangleDownIcon aria-label='sorted descending' />
                                                ) : (
                                                    <TriangleUpIcon aria-label='sorted ascending' />
                                                )
                                            ) : null}
                                        </chakra.span>
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            const rowProps = row.getRowProps();
                            return (
                                <React.Fragment key={rowProps.key}>
                                    <Tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                                                {cell.render('Cell')}
                                            </Td>
                                        ))}
                                    </Tr>
                                    {row.isExpanded ? (
                                        <tr>
                                            <td colSpan={visibleColumns.length}>
                                                {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                                                {props.renderRowSubComponent({ row })}
                                            </td>
                                        </tr>
                                    ) : null}
                                </React.Fragment>
                            )
                        })}
                    </Tbody>
                </Table></Fade>
            </Box>
            {!useInfinityScroll && <Box textAlign={'center'} padding={2}>
                <Fade in={!props.loading}>
                    <Pagination  currentPage={page} pageCount={pageCount} pageChanged={(page) => setPage(page)} />
                </Fade>
            </Box>}
        </Box>
        
    );
}
export default connect(mapStateTpProps, null) (Datatable);