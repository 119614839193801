import React from 'react';
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    chakra,
    useColorModeValue,
    ButtonGroup,
} from '@chakra-ui/react';



export default function Pagination({currentPage, pageCount, pageChanged}) {

    const [pagination, setPagination] = React.useState([]);

    const [currentPageState, setCurrentPageState] = React.useState(0);
    const [pageCountState, setPageCountState] = React.useState(0);

    const setCurrentPage = (page) => {
        pageChanged(page);
        //
    }

    const calculatePagination = (cps, pcs) => {
        
        var current = cps,
        last = pcs,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }
      setPagination(rangeWithDots);
    }

    React.useEffect(() => {

        if(pageCountState !== pageCount) {
            setPageCountState(pageCount)
        }
        else if(currentPageState !== currentPage)
        {
          setCurrentPageState(currentPage)
        }
        calculatePagination(currentPageState, pageCountState);
    }, [pageCountState, currentPageState, pageCount, currentPage]);

    return (
        <ButtonGroup>
            <Button disabled={currentPage === 1}
                    onClick={(e) => setCurrentPage(1)}>
                Erste Seite                
            </Button>
            {pagination.map((value, index) => {
                return <Button key={`Page_${index}`}
                        colorScheme={currentPage === value ? 'purple': 'gray'}
                        onClick={(e) => setCurrentPage(value)}
                        disabled={value === '...'}>
                    {value}
                </Button>
            })}
            <Button disabled={currentPage === pageCount}
                    onClick={(e) => setCurrentPage(pageCount)}>
                Letzte Seite                
            </Button>
        </ButtonGroup>
    );
}