import { CHANGE_SINGLE_KIRCHENGEMEINDE, CHANGE_KIRCHENGEMEINDEN_PAGE , CHANGE_NUMBER_OF_ELEMENTS, CHANGE_PAGE, } from "../actionTypes";



const initialState = {
    kirchengemeinden: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },
    kirchengemeinde: {
        id: -1,
        name: "",
    },
    numberOfElements: 10,
    page: 1,
    
};


const KIrchengemeindeverwaltung = (state = initialState, action) => {
    switch(action.type) {
        case CHANGE_SINGLE_KIRCHENGEMEINDE: {
            return {...state, kirchengemeinde: action.payload}
        }
        case CHANGE_KIRCHENGEMEINDEN_PAGE: {
            return {...state, kirchengemeinden: action.payload}
        }
        case CHANGE_NUMBER_OF_ELEMENTS: {
            return {...state, numberOfElements: action.payload}
        }
        case CHANGE_PAGE: {
            return {...state, page: action.payload}
        }
        
        default:
            return state;
    }
}

export default KIrchengemeindeverwaltung;