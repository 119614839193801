import React, { Children } from "react";
import { connect } from "react-redux";
import { Navigate, Route, useNavigate} from 'react-router-dom';
import { logginState } from "../pages/Login/redux/selectors";
import PropTypes from 'prop-types';


const mapStateToProps = (state) => {
    return {
        isLoggedIn: logginState(state)
    }
}


const PrivateRoute = ({ component: Component, isLoggedIn, children, ...rest }) => (
    isLoggedIn ? (
        children
    ) : (
        <Navigate to={'/login'} replace />
    )
)

PrivateRoute.propTypes = {
    component: PropTypes.any,
    location: PropTypes.any
};

export default connect(mapStateToProps, null) (PrivateRoute)

