import React from "react";
import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from "./App.jsx";
import { ChakraProvider } from '@chakra-ui/react'


import store from './store';
import { Provider } from "react-redux";

import { extendTheme } from "@chakra-ui/react"
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/700.css'

const container = document.getElementById("root");
const root = createRoot(container);




const theme = extendTheme({
    colors: {
      brand: {
        100: "#f7fafc",
        // ...
        900: "#1a202c",
      },
    },
    fonts: {
        heading: `'Roboto', sans-serif`,
        body: `'Roboto', sans-serif`,
      },
  })

root.render(<Provider store={store}>
                <ChakraProvider theme={theme}>
                    <App />
                </ChakraProvider>
            </Provider>);