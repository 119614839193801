import axios from "axios";
import TokenService from "./token.service";
import { showModal } from "../components/common/Modal/redux/actions";
import { showLoading, hideLoading } from "../components/common/Loading/redux/actions";
import pkg from '../../package.json';
import { useNavigate } from "react-router-dom";

export const APPLICATION_JSON = 'application/json';
export const TEXT_PLAIN = 'text/plain';
const loadingScreenDelay = 800;


export const url = pkg.remoteUrl;
export const token = () => TokenService.getLocalAccessToken();

const instance = axios.create({
    baseURL: url,
    headers: {
        "Content-Type": "application/json",
    },
});


instance.interceptors.request.use(
    (config) => {

        if (token()) {
            config.headers["Authorization"] = 'Bearer ' + token();  // for Spring Boot back-end
            //config.headers["x-access-token"] = token; // for Node.js Express back-end
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
     async function (err) {
        let originalConfig = err.config;
        if (originalConfig.url !== "/api/v1/login" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const rtresponse =  await instance.post("/api/v1/auth/refreshtoken", {
                        token: TokenService.getLocalRefreshToken(),
                    });
                    if(rtresponse){
                        try{
                        const access_token = rtresponse.data;
                        TokenService.updateLocalAccessToken(access_token);
                        return instance(originalConfig);
                        }catch(err){
                            TokenService.removeUser();
                            window.location.pathname = "/login"
                        }
                    }else{
                        console.log("api.js:instance.interceptors.response: rtresponse is null or empty!!! ")
                    }
                    
                    
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    }
);

const getNewDefaultRequest = (url, method = 'GET', data = null) => {
    const bodyVal = data !== null ? data : null;

    const newRequest = {
        method: method,
        url: url,
        data: bodyVal
    };

    return newRequest;
};

export const getJsonDataFromApi = async ({ dispatch, apiEndpoint, successAction, errorAction, loadingMsg, queryParams }) => {

    const cancelLoadingscreen = setTimeout(() => { dispatch(showLoading(loadingMsg)); }, loadingScreenDelay);
    const url = apiEndpoint;

    if (queryParams && queryParams instanceof Array) {
        for (let p of queryParams) {
            if (p.name && p.value) {
                url.searchParams.append(p.name, p.value);
            }
        }
    }

    const apiRequest = getNewDefaultRequest(url);

    instance(apiRequest).then(
        responseAxios => {
            clearTimeout(cancelLoadingscreen);
            dispatch(successAction(responseAxios.data));
        }

    ).catch(async function (error) {
        if (error !== undefined) {
            if (isServerError(error)) {
                await handleServerError(dispatch, error, errorAction, cancelLoadingscreen);
            }
            else {
                handleNetworkOrProcessingError(error, dispatch, errorAction, cancelLoadingscreen);
            }
        }
    }
    ).finally(() => {
        clearTimeout(cancelLoadingscreen);
        dispatch(hideLoading());
    }
    );
};

export const changeDataOnApi = async ({ dispatch, apiEndpoint, data, successAction, errorAction, loadingMsg, successActionCallback }) => {
    const cancelLoadingscreen = setTimeout(() => { dispatch(showLoading(loadingMsg)); }, loadingScreenDelay);
    const url = apiEndpoint;

    const apiRequest = getNewDefaultRequest(url, 'PUT', data);
console.log('changeDataOnApi:apirequest');
    instance(apiRequest).then(response => {
        console.log('changeDataOnApi:apirequest:then');

        clearTimeout(cancelLoadingscreen);
        if(successAction){
            dispatch(successAction(response.data));
        }
        if(successActionCallback){
            successActionCallback(dispatch, response.data);
        }
    }
    )

        .catch(async error => {
            console.log('changeDataOnApi:apirequest:catch');
            if (isServerError(error)) {
                console.log('changeDataOnApi:apirequest:catch-serror: ' + error);
                await handleServerError(dispatch, error, errorAction, cancelLoadingscreen);
            }
            else {
                console.log('changeDataOnApi:apirequest:catch-serror-else: ' + error);
                handleNetworkOrProcessingError(error, dispatch, errorAction, cancelLoadingscreen);
            }
        }).finally(() => {
            clearTimeout(cancelLoadingscreen);
            console.log("Finally");
            dispatch(hideLoading())
        });

};

export const addFormDataToApi = async ({ dispatch, apiEndpoint, formData, successAction, errorAction, loadingMsg }) => {
    const cancelLoadingscreen = setTimeout(() => { dispatch(showLoading(loadingMsg)); }, loadingScreenDelay);
    const url = apiEndpoint;


    instance.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
        clearTimeout(cancelLoadingscreen);
        dispatch(successAction(response.data));
    }
    ).catch(async error => {
        if (isServerError(error)) {
            await handleServerError(dispatch, error, errorAction, cancelLoadingscreen);
        }
        else {
            handleNetworkOrProcessingError(error, dispatch, errorAction, cancelLoadingscreen);
        }
    }
    ).finally(() => {
        clearTimeout(cancelLoadingscreen);
        dispatch(hideLoading());
    }
    );
}

export const addDataToApi = async ({ dispatch, apiEndpoint, data, successAction, errorAction, loadingMsg, successActionCallback }) => {
    const cancelLoadingscreen = setTimeout(() => { dispatch(showLoading(loadingMsg)); }, loadingScreenDelay);
    const url = apiEndpoint;

    const apiRequest = getNewDefaultRequest(url, 'POST', data);

    instance(apiRequest).then(response => {
        clearTimeout(cancelLoadingscreen);
        if(successActionCallback){
            successActionCallback(dispatch, response.data);
        }
        if(successAction){
            dispatch(successAction(response.data));
        }
    }
    ).catch(async error => {
        if (isServerError(error)) {
            await handleServerError(dispatch, error, errorAction, cancelLoadingscreen);
        }
        else {
            handleNetworkOrProcessingError(error, dispatch, errorAction, cancelLoadingscreen);
        }
    }
    ).finally(() => {
        clearTimeout(cancelLoadingscreen);
        dispatch(hideLoading());
    }
    );
};

export const deleteIdFromApi = async ({ dispatch, apiEndpoint, id, successAction, errorAction, loadingMsg }) => {
    let response;

    const cancelLoadingscreen = setTimeout(() => { dispatch(showLoading(loadingMsg)); }, loadingScreenDelay);
    const url = apiEndpoint;
    const apiRequest = getNewDefaultRequest(url, 'DELETE');

    try {
        response = await instance(apiRequest);

        if (isServerError(response)) {
            await handleServerError(dispatch, response, errorAction, cancelLoadingscreen);
        } else {
            clearTimeout(cancelLoadingscreen);
            dispatch(successAction(id));
        }
    } catch (error) {
        handleNetworkOrProcessingError(error, dispatch, errorAction, cancelLoadingscreen);
    } finally {
        clearTimeout(cancelLoadingscreen);
        dispatch(hideLoading());
    }
};

function isServerError(error) {
    if (error.response !== undefined) {
        return error.response.status >= 400 && error.response.status < 600;
    }
    else {
        return false;
    }
}

async function handleServerError(dispatch, error, errorAction, cancelLoadingscreen) {
    let detail = null;

    clearTimeout(cancelLoadingscreen);
    dispatch(hideLoading());
    if (error.response.status === 401) {
        const doLogout = () => {
            return {
                type: 'DO_LOGOUT'
            };
        };
        const setLoginErrorType = (type) => ({
            type: 'SET_LOGIN_ERROR_TYPE',
            payload: type
        });


        dispatch(doLogout());
        dispatch(setLoginErrorType('401'));
        dispatch(hideLoading());
    }
    else {
        if (typeof errorAction === 'function') {
            errorAction();
        }

        dispatch(showModal(
            'error', { message: `Es ist ein Serverfehler (${error.response.status}) beim Datentransfer aufgetreten.`, detail }
        ));
        dispatch(hideLoading())
    }
}

function handleNetworkOrProcessingError(error, dispatch, errorAction, cancelLoadingscreen) {
    clearTimeout(cancelLoadingscreen);
    if (typeof errorAction === 'function') {
        errorAction();
    }
    if (error.response !== undefined) {
        if (error.response.status > 500 && error.response.status < 600) {
            dispatch(showModal(
                'error', { message: 'Der Server ist nicht erreichbar.' }
            ));

            dispatch(hideLoading());
        }
        else {
            dispatch(showModal(
                'error', { message: `Es ist ein Fehler beim verarbeiten der Serverantwort aufgetreten: ${error}` }
            ));
            dispatch(hideLoading());
        }
    }
    else {
        dispatch(showModal(
            'error', { message: `Der Server ist nicht erreichbar: ${error}` }
        ));
        dispatch(hideLoading());
    }
}

export default instance;