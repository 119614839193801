import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Loading.scss';

import { isLoadingVisible, getMessage } from './redux/selector';

const mapStateToProps = state => {
    return {
        visible: isLoadingVisible(state),
        message: getMessage(state)
    };
};
const Loading = (props) => {
    return props.visible && <div className={'so-loading-overlay'}>
        <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div className="loading-message">{props.message}</div>
    </div>;
};

Loading.propTypes = {
    visible: PropTypes.bool.isRequired,
    message: PropTypes.string
};

export default connect(mapStateToProps,null)(Loading);