export const CHANGE_SINGLE_DOCUMENT = 'CHANGE_SINGLE_DOCUMENT';
export const CHANGE_DOCUMENTS_PAGE = 'CHANGE_DOCUMENTS_PAGE';
export const CHANGE_NUMBER_OF_ELEMENTS = 'CHANGE_NUMBER_OF_ELEMENTS';
export const CHANGE_PAGE = 'CHANGE_PAGE';

export const CHANGE_DOCUMENT = 'CHANGE_DOCUMENT';
export const CHANGE_DOCUMENT_VERSION = 'CHANGE_DOCUMENT_VERSION';
export const CHANGE_DOCUMENT_VERSION_PAGE = 'CHANGE_DOCUMENT_VERSION_PAGE';

export const WRITE_DOCUMENT_VERSION_BUFFER = 'WRITE_DOCUMENT_VERSION_BUFFER';

export const WRITE_DOCUMENT_VERSION_CHANGES = 'WRITE_DOCUMENT_VERSION_CHANGES';

export const CHANGE_DOCUMENT_VERSION_PAGE_PUSH = 'CHANGE_DOCUMENT_VERSION_PAGE_PUSH';
export const CLEAR_DOCUMENT_VERSIONS = 'CLEAR_DOCUMENT_VERSIONS';