import { CHANGE_SINGLE_ANREDE, CHANGE_ANREDE_PAGE , CHANGE_NUMBER_OF_ELEMENTS, CHANGE_PAGE } from "../actionTypes";



const initialState = {
    anreden: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },
    anrede: {
        id: 0,
        a_Anrede: "",
    },
    numberOfElements: 10,
    page: 1,
    roleRights: [],
    attachments: []
};


const anredeverwaltung = (state = initialState, action) => {
    switch(action.type) {
        case CHANGE_SINGLE_ANREDE: {
            return {...state, anrede: action.payload}
        }
        case CHANGE_ANREDE_PAGE: {
            return {...state, anreden: action.payload}
        }
        case CHANGE_NUMBER_OF_ELEMENTS: {
            return {...state, numberOfElements: action.payload}
        }
        case CHANGE_PAGE: {
            return {...state, page: action.payload}
        }
        
        default:
            return state;
    }
}

export default anredeverwaltung;