import { CHANGE_TEILNEHMERPS, CHANGE_SINGLE_TEILNEHMER, CHANGE_PAGE, CHANGE_TEILNEHMERS_PAGE, CHANGE_LANDESKIRCHE, CHANGE_SPRENGEL, CHANGE_KIRCHENKREIS, CHANGE_KIRCHENGEMEINDE,
     CHANGE_ANREDE, CHANGE_TITEL, CHANGE_FUNKTION, CHANGE_AMT, CHANGE_KURS, CHANGE_KIND , CHANGE_KINDER, CHANGE_SINGLE_KURS, CHANGE_TLNHATKURS,
    CHANGE_VIKARIATSLEITER,
    CHANGE_SINGLE_VIKARIATSLEITER,
    CHANGE_VIKARIATSLEITER_PAGE,
    CHANGE_VIKARIATSLEITER_SEL,
    CHANGE_TLNTABLEDATA,
    CHANGE_VLTABLEDATA,
} from "../actionTypes";


const initialState = {

    landeskirchen: [],
    sprengels: [],
    kirchenkreise: [],
    kirchengemeinden: [],
    anreden: [],
    titles: [],
    funktions: [],
    aemter:[],
    kurse:[],
    kinder: [],
    teilnehmers: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: [],
    },
    kind: { kindName: "",kiTaNutzung: false, kindID: 0, kindgeburtsdatum: "" },
    kurs: { id: 0, kurs_Nr: 0, beginn: "", ende: "", dozent_Oder_Referenz: "", maxTLN: 0, },
    teilnemerPS: { kursId: '', nachName: '', vorName: '', bemerkungen: '', ehemKurs: '', zukKurs: '', ausDatum: '', weDatum: '', theoExam: '', anrede: 'Frau', titel: '',
                strasse: '', wohnort: '', telefon: '', email: '', geburtstag: '', landeskirche: '', vikariatsgemeinde: '', kirchenkreis: '', sprengel: '', aemter: '', essgewohnheiten: '',
                kinderanzahl: '', kitanutzunganzahl: '', anredeVL: '', nachNameVL: '', vorNameVL: '', wohnortVL: '', telefonVL: '', emailVL: ''
                },
    teilnehmerHatKurs: false,
    teilnehmer: {id: 0,
                funktionId: -1,
                nachname: "",
                vorname: "",
                geburtsdatum: null,
                kursId: -1,
                telefon: "",
                email: "",
                bemerkung: "",
                anredeId: -1,
                title: "",
                strasse: "",
                hausnummer: 0,
                 zusatz: "",
                plz: "",
                ort: "",
                teilgenommenVon: null,
                teilgenommenBis: null,
                teilnahmebemerkung: "",
                landeskircheId: -1,
                sprengelId: -1,
                kirchenkreiseId: -1, 
                kirchengemeindeId: -1,
                amtbezeichnung: "", 
                besonderheiten: "", 
                vollkost: false,
                vegetarisch: false,
                vegan: false,
                lactosefrei: false,
                glutenfrei: false,
                fisch: false,
                kindNummer: 0,
                kinder: [],
                vikariatsleiterId: -1,
                amtId: -1,
                kursVon: null,
                kursBis: null,
                maxTLN: 0,
                kurs_Status: false,
                pruefungskommision: "",
                pruefer: "",
                kursHistory: [],
                teilnehmerHatKurs: false,
            },
    numberOfElements: 10,
    page: 1,
    roleRights: [],
    attachments: [],
    vikariatsleiter: {
        id: -1,
        anredeVL: -1,
        vornameVL: '',
        nameVL: '',
        strasseVL: '',
        hausnummerVL: 0,
        zusatzVL: '',
        pLZVL: '',
        wohnortVL: '',
        telefonVL: '',
        emailVL: ''
    },
    
    vikariatsleitende:{
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },

    vikariatsleiterSel:[],
    tlnTableData:[],
    vlTableData:[],
};


const tlnverwaltung = (state = initialState, action) => {
    switch(action.type) {
        case CHANGE_LANDESKIRCHE: {
            return {...state, landeskirchen: action.payload}
        }
        case CHANGE_SPRENGEL: {
            return {...state, sprengels: action.payload}
        }
        case CHANGE_KIRCHENKREIS: {
            return {...state, kirchenkreise: action.payload}
        }
        case CHANGE_KIRCHENGEMEINDE: {
            return {...state, kirchengemeinden: action.payload}
        }
        case CHANGE_ANREDE: {
            return {...state, anreden: action.payload}
        }
        case CHANGE_TITEL: {
            return {...state, titles: action.payload}
        }
        case CHANGE_FUNKTION: {
            return {...state, funktions: action.payload}
        }
        case CHANGE_AMT: {
            return {...state, aemter: action.payload}
        }
        case CHANGE_KURS: {
            return {...state, kurse: action.payload}
        }
        case CHANGE_SINGLE_KURS: {
            return {...state, kurs: action.payload}
        }
        case CHANGE_KIND: {
            return {...state, kind: action.payload}
        }
        case CHANGE_KINDER: {
            return {...state, kinder: action.payload}
        }
        case CHANGE_TEILNEHMERS_PAGE: {
            return {...state, teilnehmers: action.payload}
        }
        case CHANGE_PAGE: {
            return {...state, page: action.payload}
        }
        case CHANGE_SINGLE_TEILNEHMER: {
            return {...state, teilnehmer: action.payload}
        }
        case CHANGE_TEILNEHMERPS: {
            return{...state, teilnemerPS: action.payload}
        }
        case CHANGE_VIKARIATSLEITER: {
            return{...state, vikariatsleitende: action.payload}
        }
        case CHANGE_SINGLE_VIKARIATSLEITER: {
            return{...state, vikariatsleiter: action.payload}
        }
        case CHANGE_VIKARIATSLEITER_PAGE: {
            return{...state, vikariatsleitende: action.payload}
        }
        case CHANGE_VIKARIATSLEITER_SEL: {
            return{...state, vikariatsleiterSel: action.payload}
        }

        case CHANGE_TLNHATKURS: {
            return{...state, teilnehmerHatKurs: action.payload}
        }
        case CHANGE_TLNTABLEDATA: {
            return{...state, tlnTableData: action.payload}
        }
        case CHANGE_VLTABLEDATA: {
            return{...state, vlTableData: action.payload}
        }
        
        default:
            return state;
    }
};

export default tlnverwaltung;