var TokenService = /** @class */ (function () {
    function TokenService() {
    }
    TokenService.prototype.getLocalRefreshToken = function () {
        var user = JSON.parse(localStorage.getItem("user"));
        return user === null || user === void 0 ? void 0 : user.refreshToken;
    };
    TokenService.prototype.getLocalAccessToken = function () {
        var user = JSON.parse(localStorage.getItem("user"));
        return user === null || user === void 0 ? void 0 : user.jwtToken;
    };
    TokenService.prototype.updateLocalAccessToken = function (token) {
        var user = JSON.parse(localStorage.getItem("user"));
        user.jwtToken = token;
        localStorage.setItem("user", JSON.stringify(user));
    };
    TokenService.prototype.getUser = function () {
        return JSON.parse(localStorage.getItem("user"));
    };
    TokenService.prototype.setUser = function (user) {
        console.log(JSON.stringify(user));
        localStorage.setItem("user", JSON.stringify(user));
    };
    TokenService.prototype.removeUser = function () {
        localStorage.removeItem("user");
    };
    return TokenService;
}());
export default new TokenService();
