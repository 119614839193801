import { CHANGE_TABLECONFIG,CHANGE_TABLECONFIGS, CHANGE_TABLEDATA, CHANGE_TEMPLATENAME, CHANGE_REQSTATUS, CHANGE_GHMDATA, CHANGE_GRPHAUS } from "../actionTypes";

const initialState = {
    templateName: "",
    tableConfig: {
        id: 0,
        templateName: "",
        columnFilters: {},
        globalFilter: {},
        pagination: {},
        columnVisibility: {},
    },
    tableConfigs: [],
    tableData: [],
    reqstatus: "",
    ghmExportData: {
        fromDate: "",
        toDate: "",
        gruppenhausId: 0,
        tlnDataRows: []
    },
    grphaus: []
};

const psgesamtliste = (state = initialState, action) => {
    switch(action.type) {
        case CHANGE_TEMPLATENAME: {
            return {...state, templateName: action.payload}
        }
        case CHANGE_TABLECONFIG: {
            return {...state, tableConfig: action.payload}
        }
        case CHANGE_TABLECONFIGS: {
            return {...state, tableConfigs: action.payload}
        }
        case CHANGE_TABLEDATA: {
            return {...state, tableData: action.payload}
        }
        case CHANGE_REQSTATUS: {
            return {...state, reqstatus: action.payload}
        }
        case CHANGE_GHMDATA:{
            return {...state, ghmExportData: action.payload}
        }
        case CHANGE_GRPHAUS:{
            return {...state, grphaus: action.payload}
        }
        default:
            return state;
    }
};

export default psgesamtliste;