import { CHANGE_SINGLE_KURS, CHANGE_KURS_PAGE , CHANGE_NUMBER_OF_ELEMENTS, CHANGE_PAGE } from "../actionTypes";



const initialState = {
    kurse: {
        elementCount: 10,
        page: 1,
        pageCount: 1,
        values: []
    },
    kurs: {
        id: 0,
        kurs_Nr: "",
        beginn: null,
        ende: null,
        dozent_Oder_Referenz: "",
        maxTLN: 0
    },
    numberOfElements: 10,
    page: 1,
    
};


const kursverwaltung = (state = initialState, action) => {
    switch(action.type) {
        case CHANGE_SINGLE_KURS: {
            return {...state, kurs: action.payload}
        }
        case CHANGE_KURS_PAGE: {
            return {...state, kurse: action.payload}
        }
        case CHANGE_NUMBER_OF_ELEMENTS: {
            return {...state, numberOfElements: action.payload}
        }
        case CHANGE_PAGE: {
            return {...state, page: action.payload}
        }
        
        default:
            return state;
    }
}

export default kursverwaltung;