import React, { Suspense } from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createTheme, darken, ThemeProvider, useTheme } from '@mui/material';
const SidebarWithHeader = React.lazy(() => import('./components/common/Sidebar'));
const Login = React.lazy(() => import('./components/pages/Login/Login'));
const OpenPasswordReset = React.lazy(() => import('./components/pages/Login/OpenPasswordReset'));
const PasswordReset = React.lazy(() => import('./components/pages/Login/PasswordReset'));
const Dokumentenverwaltung = React.lazy(() => import('./components/pages/Dokumentenverwaltung/Dokumentenverwaltung'));
const Kundenverwaltung = React.lazy(() => import('./components/pages/Kundenverwaltung/Kundenverwaltung'));
const Administration = React.lazy(() => import('./components/pages/Administration/Administration'));
const Teilnehmerverwaltung = React.lazy(() => import('./components/pages/Teilnehmerverwaltung/Teilnehmerverwaltung'));
const Vikariatsleiterverwaltung = React.lazy(() => import('./components/pages/Teilnehmerverwaltung/Vikariatsleiterverwaltung'));
const Systemtabellenverwaltung = React.lazy(() => import('./components/pages/Systemtabellenverwaltung/Systemtabellenverwaltung'));
const Anredeverwaltung = React.lazy(() => import('./components/pages/Anredeverwaltung/Anredeverwaltung'));
const Funktionverwaltung = React.lazy(() => import('./components/pages/Funktionverwaltung/Funktionverwaltung'));
const Amtverwaltung = React.lazy(() => import('./components/pages/Amtverwaltung/Amtverwaltung'));
const Kursverwaltung = React.lazy(() => import('./components/pages/Kursverwaltung/Kursverwaltung'));
const Kirchenkreisverwaltung = React.lazy(() => import('./components/pages/Kirchenkreisverwaltung/Kirchenkreisverwaltung'));
const Kirchengemeindeverwaltung = React.lazy(() => import('./components/pages/Kirchengemeindeverwaltung/Kirchengemeindeverwaltung'));
const Landeskirchenverwaltung = React.lazy(() => import('./components/pages/Landeskirchenverwaltung/Landeskirchenverwaltung'));
const Psgesamtliste = React.lazy(() => import('./components/pages/PSGesamtliste/PSGesamtliste'));
const Exportverwaltung = React.lazy(() => import('./components/pages/Exportverwaltung/Exportverwaltung'));
const Personenverwaltung = React.lazy(() => import('./components/pages/Personenverwaltung/Personenverwaltung'));
const Sprengelverwaltung = React.lazy(() => import('./components/pages/SprengelVerwaltung/Sprengelverwaltung'));
const Gruppenhausverwaltung = React.lazy(() => import('./components/pages/Gruppenhausverwaltung/Gruppenhausverwaltung'));

import { HubConnectionBuilder } from '@microsoft/signalr';

import {
    TransitionGroup,
    CSSTransition
} from "react-transition-group";

import "./App.css";
import ModalDialogRoot from './components/common/Modal/ModalDialogRoot';
import PrivateRoute from './components/common/PrivateRoute';
import Userverwaltung from './components/pages/Userverwaltung/Userverwaltung';
import Rollenverwaltung from './components/pages/Rollenverwaltung/Rollenverwaltung';
import FileUploadPage from './components/pages/UploadList/UploadList'

import { getVersion } from './components/pages/Settings/redux/selectors';
import { getVersionFromServer } from './components/pages/Settings/redux/actions';




function App(props) {

    
   


    let connection = new HubConnectionBuilder()
        .withUrl("/chat")
        .build();

    connection.on("send", data => {
        console.log(data);
    });

    React.useEffect(() => {
        props.getVersionFromServer();
    }, []);

    return (
       
        <BrowserRouter>
            <SidebarWithHeader>
                <Suspense fallback={<div>Loading...</div>}>
                    <ModalDialogRoot />
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/resetPassword" element={<OpenPasswordReset />} />
                        <Route path="/resetPassword/:token" element={<PasswordReset />} />
                        {/*
                        <Route path="/kundenverwaltung" element={
                            <PrivateRoute>
                                <Kundenverwaltung />
                            </PrivateRoute>
                        } />
                    */}
                         <Route path="/psgesamtliste" element={
                            <PrivateRoute>
                                <Psgesamtliste />
                            </PrivateRoute>
                        } />
                        {/* <Route path="/teilnehmerverwaltung" element={
                            <PrivateRoute>
                                <Teilnehmerverwaltung />
                            </PrivateRoute>
                        } /> */}
                        <Route path="/personenverwaltung" element={
                            <PrivateRoute>
                                <Personenverwaltung />
                            </PrivateRoute>
                        }>
                            <Route path="teilnehmerverwaltung" element={
                                <PrivateRoute>
                                    <Teilnehmerverwaltung />
                                </PrivateRoute>
                            } />
                            <Route path="vikariatsleiterverwaltung" element={
                                <PrivateRoute>
                                    <Vikariatsleiterverwaltung />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path="/systemtabellenverwaltung" element={
                            <PrivateRoute>
                                <Systemtabellenverwaltung />
                            </PrivateRoute>
                        }>
                            
                            <Route path="anredeverwaltung" element={
                                <PrivateRoute>
                                    <Anredeverwaltung />
                                </PrivateRoute>
                            } />
                            <Route path="funktionverwaltung" element={
                                <PrivateRoute>
                                    <Funktionverwaltung />
                                </PrivateRoute>
                            } />
                            <Route path="amtverwaltung" element={
                                <PrivateRoute>
                                    <Amtverwaltung />
                                    </PrivateRoute>
                            } />
                            <Route path="kursverwaltung" element={
                                <PrivateRoute>
                                    <Kursverwaltung />
                                </PrivateRoute>
                            } />
                            <Route path="Kirchenkreisverwaltung" element={
                                <PrivateRoute>
                                    <Kirchenkreisverwaltung />
                                </PrivateRoute>
                            } />
                            <Route path="kirchengemeindeverwaltung" element={
                                <PrivateRoute>
                                    <Kirchengemeindeverwaltung />
                                </PrivateRoute>
                            } />
                            <Route path="exportverwaltung" element={
                                <PrivateRoute>
                                    <Exportverwaltung />
                                </PrivateRoute>
                            } />
                            <Route path="sprengelverwaltung" element={
                                <PrivateRoute>
                                    <Sprengelverwaltung />
                                </PrivateRoute>
                            } />
                            <Route path="landeskirchenverwaltung" element={
                                <PrivateRoute>
                                    <Landeskirchenverwaltung />
                                </PrivateRoute>
                            } />
                            <Route path="gruppenhausverwaltung" element={
                                <PrivateRoute>
                                    <Gruppenhausverwaltung />
                                </PrivateRoute>
                            } />
                            
                        </Route>


                        <Route path="/administration" element={
                            <PrivateRoute>
                                <Administration />
                            </PrivateRoute>
                        }>
                            <Route path='userverwaltung' element={
                                <PrivateRoute>
                                    <Userverwaltung />
                                </PrivateRoute>
                            }>

                            </Route>
                            <Route path='rollenverwaltung' element={
                                <PrivateRoute>
                                    <Rollenverwaltung />
                                </PrivateRoute>
                            }>
                            </Route>
                            <Route path='importverwaltung' element={
                                <PrivateRoute>
                                    <FileUploadPage />
                                </PrivateRoute>
                            }>
                            </Route>
                        </Route>
                    </Routes>
                </Suspense>
            </SidebarWithHeader>
        </BrowserRouter>

    )
}

App.propTypes = {}

const mapDispatchToProps = {
    getVersionFromServer
};

export default connect(null, mapDispatchToProps)(App);
